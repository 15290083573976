import React from 'react'
import { useDispatch } from 'react-redux'
import { addReactionToDream } from '../../../ducks/dream-tag/thunks/dream-thunks'
import { idEquals } from '../../../ducks/helpers'
import { DreamFeedListNavigationProp } from './feed-list-dream'
import {
  DreamerViewNavigationProp,
  DreamerViewRouteProp,
} from '../../../screens/Dreamer/DreamerView'
import {
  SearchComponentNavigationProp,
  SearchComponentRouteProp,
} from '../../../screens/Search/search-inner'
import {
  TagViewNavigationProp,
  TagViewRouteProp,
} from '../../../screens/TagView'
import { FeedRouteProp } from '../../../screens/Feed/GroupFeed'
import { PrivateDraftsFeedRouteProp } from '../../../screens/Drafts/DraftsFeed'
import { RootStackParamList } from '../../../navigation/types'
import { FastDream, FastUser } from '../../../../../api/_openapi'
import { FeedEntryGeneric } from './feed-entry-generic'
import { useLinkProps } from '../../../utilities/useLinkPropsFixed'
import { useSelector } from '../../../ducks/root-reducer'
import { selectUserMembershipInGroup } from '../../../ducks/groups/groups'
import { updateCloudinaryUrlForDisplay } from '../../../modules/image-helpers/image-helpers'
import { SM_THUMB_RES } from '../../../constants/ui-constants'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { PrivateDreamerPathRouteProp } from '../../../screens/PrivateDreamerPaths/PathView'
import { DeleteDreamAlert } from './dream-ellipsis/delete-dream-alert'
import { useDreamActions } from '../../../hooks/useDreamActions'

export type FeedItemDreamNavigationProp =
  | DreamFeedListNavigationProp
  | DreamerViewNavigationProp
  | SearchComponentNavigationProp
  | TagViewNavigationProp
export type FeedItemDreamRouteProp =
  | DreamerViewRouteProp
  | TagViewRouteProp
  | SearchComponentRouteProp
  | FeedRouteProp
  | PrivateDraftsFeedRouteProp
  | PrivateDreamerPathRouteProp

type FeedItemDreamProps = {
  route: FeedItemDreamRouteProp
  dream: FastDream
  index: number
  privateGroupId?: string
  currentGroup?: any
  appUser: FastUser | null
  activeGroupId: string
  showType?: boolean
}

const FeedItemDream = ({
  route,
  dream,
  index,
  privateGroupId,
  currentGroup,
  appUser,
  activeGroupId,
  showType = false,
}: FeedItemDreamProps) => {
  // HOOKS
  const actions = useDreamActions(dream?.id)
  const dispatch = useDispatch<any>()

  // VARS
  const dreamLanguage = dream.languageCode as SupportedLanguage
  const isDraft = Boolean(dream.isDraft)
  const isUserPrivateGroup = idEquals(activeGroupId, privateGroupId)
  const isLibrary = route.name === 'LibraryDreamerView'
  const isSearch = route.name === 'PrivateSearch'

  // const activeUserId = authorId || userId || null
  const membership = useSelector(
    selectUserMembershipInGroup(dream.userId, activeGroupId),
  )

  let linkToScreen = 'PrivateDreamView'
  if (isLibrary) {
    linkToScreen = 'LibraryDreamView'
  } else if (isDraft) {
    linkToScreen = 'PrivateDraftView'
  } else if (dream.type === 'journal-entry') {
    linkToScreen = 'PrivateDiaryView'
  } else if (isUserPrivateGroup || isSearch) {
    linkToScreen = 'PrivateDreamView'
  } else {
    linkToScreen = 'GroupDreamView'
  }

  const linkObject = isLibrary
    ? {
        screen: linkToScreen as keyof RootStackParamList,
        params: {
          username: route?.params?.username,
          id: dream.id,
        },
      }
    : {
        screen: linkToScreen as keyof RootStackParamList,
        params: {
          id: dream.id,
          groupId: isUserPrivateGroup ? undefined : currentGroup?.id,
        },
      }

  const userInfoLinkObject = {
    screen: isUserPrivateGroup
      ? 'PrivateDreamerView'
      : ('GroupDreamerView' as keyof RootStackParamList),
    params: {
      groupId: isUserPrivateGroup ? undefined : activeGroupId,
      userId: membership?.userId || appUser?.id,
    },
  }

  const userInfoLinkProps = {
    to: userInfoLinkObject,
  }

  // @ts-ignore
  const { onPress: avatarOnPress } = useLinkProps(userInfoLinkProps)

  const addOrRemoveReaction = () => {
    dispatch(
      addReactionToDream(dream.id, dream.shareId, dream.userReactionCount),
    )
  }

  const avatarImgUrl = updateCloudinaryUrlForDisplay(
    membership?.imageUrl,
    SM_THUMB_RES,
  )

  return (
    <>
      <FeedEntryGeneric
        language={dreamLanguage}
        titleLanguage={dreamLanguage}
        inGroup={
          currentGroup && privateGroupId && privateGroupId !== currentGroup.id
        }
        type={showType ? dream.type : undefined}
        title={dream.title}
        date={dream.date}
        displayDate={dream.displayDate}
        description={dream.description}
        imageUrl={dream.imageUrl}
        actionSheetCanOpen={actions.canOpen}
        actionSheetMenuItems={actions.actionSheetMenuItems}
        avatarCircleSrc={{ uri: avatarImgUrl || undefined }}
        avatarLabel={membership?.displayName || ''}
        avatarSubLabel={dream.place}
        hasTopDivider={true}
        linkObject={linkObject}
        userInfoLinkObject={userInfoLinkObject}
        avatarOnPress={avatarOnPress}
        truncate={true}
        commentCount={dream.commentCount}
        reactionCount={dream.reactionCount}
        userReactionCount={dream.userReactionCount}
        showReactionRow={!dream.isDraft}
        onReactionPress={addOrRemoveReaction}
      />
      {actions.isDeleteAlertOpen && (
        <DeleteDreamAlert
          isOpen={actions.isDeleteAlertOpen}
          onClose={() => {
            actions.setIsDeleteAlertOpen(false)
          }}
          dream={dream}
        />
      )}
    </>
  )
}

export default FeedItemDream
