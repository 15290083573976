import { RootState } from '../root-reducer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SUPPORTED_LANGUAGES } from '../../i18n/config'
import { PURGE } from 'redux-persist'
import { PurchasesPackage } from '../../revenuecat'
import { getTiersForFeature } from '../../modules/subscriptions/subscription-helpers'
import { undecorateId } from '../../../../api/decorate-ids'
import { navigate } from '../../navigation/RootNavigation'
import { DEFAULT_LANGUAGE } from '../../constants/constants'
import {
  ArtStyleKey,
  DreamLoadingInformation,
  FormFieldOption,
  InterpretationStyleKey,
  SubscriptionTierKey,
} from '../../../../api/frontend-types'
import {
  ArtStyle,
  FastDream,
  FastTag,
  FastUser,
  Gender,
  Group,
  InterpretationOptions,
  InterpretationStyle,
  Language,
} from '../../../../api/_openapi'
import { ElsewhereIconType } from '../../modules/ui-helpers/icon-map'
import { createObjectMap } from '../dream-tag/modules/normalize'
import { getScriptTypeForLanguage } from '../../modules/language-helpers/language-helpers'

// How to order interpretation styles
const interpPriorities: { [key in InterpretationStyleKey]: number } = {
  standard: 0,
  jungian: 1,
  freudian: 2,
  biblical: 3,
  spiritual: 4,
  relationship: 5,
  none: 4,
}

// How to order art styles
const artStylePriorities: { [key in ArtStyleKey]: number } = {
  default: 0,
  old_illustration: 1,
  storybook: 2,
  surrealist_collage: 3,
  comic_book: 4,
  woodblock: 5,
  holga: 6,
  dalle3_plain: 7,
  custom: 8,
  none: 9,
}

export const sortInterpStyles = (interps: InterpretationStyle[] = []) => {
  return interps.sort((a, b) => {
    return interpPriorities[a.key] - interpPriorities[b.key]
  })
}

export const sortArtStyles = (artstyles: ArtStyle[] = []) => {
  return artstyles.slice().sort((a, b) => {
    return artStylePriorities[a.name] - artStylePriorities[b.name]
  })
}

const initialFeatureMap: FeatureMap = {
  free: [],
  premium: [],
  supporter: [],
  temp: [],
}

export type DreamLoadingMap = {
  [dreamId: string]: {
    loadingByField: DreamLoadingInformation
  }
}

export type FeatureEntry = {
  featureId: string
  tierId: string
  featureName: string
  usageLimit: number | null
  usagePeriod: string | null
}

export type FeatureMap = {
  [key in SubscriptionTierKey]: FeatureEntry[]
}

export type UIState = {
  dreamsLoading: DreamLoadingMap
  // General details we need for forms and such
  genders: Gender[]
  famousUsers: { [key: string]: FastUser }
  dreamTypes: FastTag[]
  themes: FastTag[]
  symbolTags: FastTag[]
  languages: Language[]
  artStyles: ArtStyle[]
  interpretationStyles: InterpretationStyle[]
  interpretationOptions: InterpretationOptions[]
  featureMap: FeatureMap
  feedRefreshLoading: boolean
  groupsRefreshLoading: boolean
  groupIdFromRoute: string | null
  friendUsernameFromRoute: string | null
  isPurchasing: boolean
  purchasedPackage: null | PurchasesPackage
  userGroups: Group[]
  activeGroupId?: string
  // String is dream id where it was viewed + part of time stamp (10 mins)
  noCreditsMessageViewed: boolean | string
  hasViewedYearlyReport: boolean
  alwaysDetectDreamLocation: boolean
  isOffline: boolean
  lastSignInVersion: null | string
  requiresUpdate: boolean
}

const initialState: UIState = {
  dreamsLoading: {},
  genders: [],
  famousUsers: {},
  dreamTypes: [],
  themes: [],
  symbolTags: [],
  artStyles: [],
  interpretationStyles: [],
  interpretationOptions: [],
  featureMap: initialFeatureMap,
  languages: [],
  feedRefreshLoading: false,
  groupsRefreshLoading: false,
  groupIdFromRoute: null,
  friendUsernameFromRoute: null,
  isPurchasing: false,
  purchasedPackage: null,
  userGroups: [],
  activeGroupId: undefined,
  noCreditsMessageViewed: false,
  hasViewedYearlyReport: false,
  alwaysDetectDreamLocation: false,
  isOffline: false,
  lastSignInVersion: null,
  requiresUpdate: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState
    })
  },
  reducers: {
    setGenders(state, action: PayloadAction<Gender[]>) {
      state.genders = action.payload || []
    },
    setDreamTypes(state, action: PayloadAction<FastTag[]>) {
      state.dreamTypes = action.payload || []
    },
    setFamousUsers(state, action: PayloadAction<FastUser[]>) {
      state.famousUsers = createObjectMap(action.payload || [])
    },
    setThemes(state, action: PayloadAction<FastTag[]>) {
      state.themes = action.payload || []
    },
    setSymbolTags(state, action: PayloadAction<FastTag[]>) {
      state.symbolTags = action.payload
    },
    setArtStyles(state, action: PayloadAction<ArtStyle[]>) {
      state.artStyles = sortArtStyles(action.payload || [])
    },
    setInterpretationStyles(
      state,
      action: PayloadAction<InterpretationStyle[]>,
    ) {
      state.interpretationStyles = sortInterpStyles(action.payload || [])
    },
    setInterpretationOptions(
      state,
      action: PayloadAction<InterpretationOptions[]>,
    ) {
      state.interpretationOptions = action.payload || []
    },
    setFeatureMap(state, action: PayloadAction<FeatureMap>) {
      state.featureMap = action.payload
    },
    setLanguages(state, action: PayloadAction<Language[]>) {
      const languages = action.payload || [DEFAULT_LANGUAGE]
      state.languages = languages.filter(
        (l) => l.supported && SUPPORTED_LANGUAGES.includes(l.code),
      )
    },
    setFeedRefreshLoading(state, action: PayloadAction<boolean>) {
      state.feedRefreshLoading = action.payload
    },
    setGroupsRefreshLoading(state, action: PayloadAction<boolean>) {
      state.groupsRefreshLoading = action.payload
    },
    setGroupIdFromRoute(state, action: PayloadAction<string | null>) {
      state.groupIdFromRoute = action.payload
    },
    setFriendUsernameFromRoute(state, action: PayloadAction<string | null>) {
      state.friendUsernameFromRoute = action.payload
    },
    setIsPurchasing(state, action: PayloadAction<boolean>) {
      state.isPurchasing = action.payload
    },
    setPurchasedPackage(state, action: PayloadAction<PurchasesPackage | null>) {
      state.purchasedPackage = action.payload
    },
    // You can set different fields of the dream to be loading
    // E.g. tags, image, etc.
    setDreamFieldsLoading(
      state,
      action: PayloadAction<{
        dreamId: string | undefined
        fields: (keyof FastDream)[]
        loading: boolean
      }>,
    ) {
      const { dreamId, loading, fields } = action.payload

      if (!dreamId) {
        return
      }

      const initialState = state.dreamsLoading[dreamId]?.loadingByField || {}
      const newLoadingState = fields.reduce((acc, field) => {
        return {
          ...acc,
          [field]: loading,
        }
      }, initialState)

      state.dreamsLoading[dreamId] = {
        loadingByField: newLoadingState,
      }
    },
    setNoCreditsMessageViewed(state, action: PayloadAction<boolean | string>) {
      state.noCreditsMessageViewed = action.payload
    },
    setAlwaysDetectDreamLocation(state, action: PayloadAction<boolean>) {
      state.alwaysDetectDreamLocation = action.payload
    },
    setIsOffline(state, action: PayloadAction<boolean>) {
      state.isOffline = action.payload
    },
    setLastSignInVersion(state, action: PayloadAction<string>) {
      state.lastSignInVersion = action.payload
    },
    setRequiresUpdate(state, action: PayloadAction<boolean>) {
      state.requiresUpdate = action.payload
    },
    setHasViewedYearlyReport(state, action: PayloadAction<boolean>) {
      state.hasViewedYearlyReport = action.payload
    },
  },
})

export const {
  setDreamFieldsLoading,
  setGenders,
  setDreamTypes,
  setThemes,
  setSymbolTags,
  setArtStyles,
  setInterpretationStyles,
  setInterpretationOptions,
  setFeatureMap,
  setLanguages,
  setFeedRefreshLoading,
  setGroupsRefreshLoading,
  setGroupIdFromRoute,
  setFriendUsernameFromRoute,
  setFamousUsers,
  setIsPurchasing,
  setPurchasedPackage,
  setNoCreditsMessageViewed,
  setAlwaysDetectDreamLocation,
  setIsOffline,
  setLastSignInVersion,
  setRequiresUpdate,
  setHasViewedYearlyReport,
} = uiSlice.actions

export const selectGenderOptions = (state: RootState): FormFieldOption[] => {
  const genders = state?.ui?.genders || []
  return genders.map((g) => ({ value: g.id as string, label: g.name || '' }))
}

export const selectDreamTypeOptions = (state: RootState): FormFieldOption[] => {
  const dreamTypes = state?.ui.dreamTypes || []
  return dreamTypes.map((dt) => ({
    value: dt.id as string,
    label: dt.name as string,
  }))
}

export const selectDreamTypes = (state: RootState): FastTag[] => {
  return state?.ui.dreamTypes
}

export const selectLucidDreamTypeTag = (
  state: RootState,
): FastTag | undefined => {
  return state?.ui.dreamTypes.find((dt) => dt.name === 'Lucid')
}

export const selectNightmareDreamTypeTag = (
  state: RootState,
): FastTag | undefined => {
  return state?.ui.dreamTypes.find((dt) => dt.name === 'Nightmare')
}

export const selectThemeOptions = (state: RootState): FormFieldOption[] => {
  const themes = state?.ui.themes || []
  return themes.map((t) => ({ value: t.id as string, label: t.name as string }))
}

// SELECTORS
export const selectSymbolTags = (state: RootState): FastTag[] => {
  return state.ui.symbolTags
}

export const selectAllPossibleArtStyleOptions =
  (includeNoInterpStyle: boolean = true) =>
  (state: RootState): FormFieldOption[] => {
    // State vars
    const featureMap = state?.ui.featureMap
    const artStylesUnsorted = state?.ui.artStyles || []
    const artStyles = sortArtStyles(artStylesUnsorted)
    const userTier =
      (state?.user?.user?.subscriptionTier as SubscriptionTierKey) || 'free'
    const userCredits = state.user.user?.creditsAvailable || 0
    const userHasCredits = userCredits > 0

    return artStyles
      .filter((as) => includeNoInterpStyle || as.name !== 'none')
      .map((as) => {
        const styleKey = `art_style.${as.name}`
        const tiersWithArtStyle = getTiersForFeature(styleKey, featureMap)
        const userCanAccess =
          tiersWithArtStyle.includes(userTier) || userHasCredits
        const isPremiumStyle = !tiersWithArtStyle.includes('free')
        return {
          value: as.id as string,
          label: as.name ? `artStyles.${as.name}` : '',
          languageKey: as.name ? `artStyles.${as.name}` : '',
          iconKey: isPremiumStyle ? 'gem' : undefined,
          locked: !userCanAccess,
          lockedAction: () => {
            navigate('PrivateSubscriptionSettingsEdit', {
              tab: 'premium',
            })
          },
        }
      })
  }

export const selectInterpretationStyles = (
  state: RootState,
): InterpretationStyle[] => {
  return state?.ui.interpretationStyles || []
}

export const selectInterpretationOptions = (
  state: RootState,
): InterpretationOptions[] => {
  return state?.ui.interpretationOptions || []
}

// Returns all interpretation styles
// Marking them as locked if the user doesn't have access
// And noting if they are premium styles
// "None" interpretation style can be optionally removed
export const selectAllInterpretationStyleOptions =
  (includeNoInterpStyle: boolean) =>
  (state: RootState): FormFieldOption[] => {
    // State vars
    const featureMap = state?.ui.featureMap
    const interpretationStylesUnsorted = state?.ui.interpretationStyles || []
    const interpretationStyles = sortInterpStyles([
      ...interpretationStylesUnsorted,
    ])

    // See if the user is premium/supporter or has credits
    // If so, the item is not locked
    const userTier =
      (state?.user?.user?.subscriptionTier as SubscriptionTierKey) || 'free'
    const userCredits = state.user.user?.creditsAvailable || 0
    const userHasCredits = userCredits > 0

    return interpretationStyles
      .filter((is) => includeNoInterpStyle || is.key !== 'none')
      .map((is: InterpretationStyle) => {
        const styleKey = `interpretation_style.${is.key}`
        const tiersWithStyle = getTiersForFeature(styleKey, featureMap)
        const userCanAccess =
          tiersWithStyle.includes(userTier) || userHasCredits
        const isPremiumStyle = !tiersWithStyle.includes('free')
        const i18nKey = is.name ? `interpretationStyles.${is.key}` : ''

        return {
          value: is.id as string,
          label: i18nKey,
          languageKey: i18nKey,
          iconKey: isPremiumStyle ? ('gem' as ElsewhereIconType) : undefined,
          isPremium: isPremiumStyle,
          locked: !userCanAccess,
        }
      })
  }

// This is used for the dream settings edit form
export const selectAllInterpretationStyleOptionsForForm = (
  state: RootState,
): FormFieldOption[] => {
  const optionsWithoutAction = selectAllInterpretationStyleOptions(false)(state)
  return optionsWithoutAction.map((o) => ({
    ...o,
    lockedAction: () => {
      navigate('PrivateSubscriptionSettingsEdit', {
        tab: 'premium',
      })
    },
  }))
}

// Select a dream from the store, and gets its tags as well
export const selectInterpStyleById =
  (id: string) =>
  (state: RootState): InterpretationStyle | undefined => {
    // Make sure id is undecorated
    const idToFind = undecorateId(id)
    return state?.ui.interpretationStyles.find((is) => {
      return is.id === idToFind
    })
  }

export const selectLanguageOptions = (state: RootState): FormFieldOption[] => {
  const languages = state?.ui.languages || []
  return languages.map((l) => ({
    value: `${l.code}`,
    label: `languages.${l.code}`,
    subLabel: `languages.${l.code}`,
    languageKey: `languages.${l.code}`,
    subLabelScriptType: getScriptTypeForLanguage(l.code),
  }))
}

export const selectDreamFieldIsLoading =
  (id: string, field: keyof FastDream) =>
  (state: RootState): boolean => {
    return Boolean(state?.ui.dreamsLoading[id]?.loadingByField[field])
  }

export const selectFeedRefreshLoading = (state: RootState): boolean => {
  // This absolutely needs to be a boolean for FlatList
  return state?.ui.feedRefreshLoading || false
}

export const selectGroupsRefreshLoading = (state: RootState): boolean => {
  // This absolutely needs to be a boolean for FlatList
  return state?.ui.groupsRefreshLoading || false
}

export const selectGroupIdFromRoute = (state: RootState): string | null => {
  return state?.ui.groupIdFromRoute || null
}

//todo: deprecated
export const selectFriendUsernameFromRoute = (
  state: RootState,
): string | null => {
  //todo: this is no longer set, replace
  return state?.ui.friendUsernameFromRoute || null
}

export const selectIsPurchasing = (state: RootState): boolean => {
  // This absolutely needs to be a boolean for FlatList
  return state?.ui.isPurchasing || false
}

export const selectPurchasedPackage = (
  state: RootState,
): PurchasesPackage | null => {
  // This absolutely needs to be a boolean for FlatList
  return state?.ui.purchasedPackage || null
}

export const selectNoCreditsMessageViewed = (
  state: RootState,
): boolean | string => {
  return state?.ui.noCreditsMessageViewed
}

export const selectHasViewedYearlyReport = (state: RootState): boolean => {
  // If today's date is after Jan 19 2025, we assume they have seen it
  // Or they don't care about the yearly report
  if (new Date() > new Date('2025-01-19')) {
    return true
  }
  return state?.ui.hasViewedYearlyReport
}

export const selectAlwaysDetectDreamLocation = (state: RootState): boolean => {
  return state?.ui.alwaysDetectDreamLocation
}

export const selectIsOffline = (state: RootState): boolean => {
  return state?.ui.isOffline
}

export const selectLastSignInVersion = (state: RootState): string | null => {
  return state?.ui.lastSignInVersion
}

export const selectRequiresUpdate = (state: RootState): boolean => {
  return state?.ui.requiresUpdate
}

export const selectPublicUserById =
  (id: string | null | undefined) =>
  (state: RootState): FastUser | undefined => {
    if (!id) {
      return undefined
    }
    return state?.ui.famousUsers[id]
  }

export default uiSlice
