import { FormFieldOption } from '../../../../api/frontend-types'

//0=never, 1=less than once a month, 2=about once a month, 3=about 2 to 3 times a month, 4=about once a week, 5=several times a week, 6=almost every morning

const opts: FormFieldOption[] = [6, 5, 4, 3, 2, 1, 0].map((i) => {
  return {
    label: `signupForm.recallFrequency.option${i}`,
    languageKey: `signupForm.recallFrequency.option${i}`,
    value: i.toString(),
  }
})

const dreamRecallFrequencyOptions: FormFieldOption[] = [
  ...opts,
  {
    label: 'Prefer not to answer',
    languageKey: 'Prefer not to answer',
    value: '',
  },
]

export { dreamRecallFrequencyOptions }
