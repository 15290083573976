import React from 'react'
import { SansText } from '../../components/common/copy/text-sans'
import {
  EndOfYearWrapped,
  InterpretationStyle,
  Tag,
} from '../../../../api/_openapi'
import { useTranslation } from 'react-i18next'
import { Column } from '../../components/common/column/column'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { BodyText } from '../../components/common/copy/text-body'
import { Factoid } from '../../components/composite/stats/factoid'
import { CloudIcon } from '../../assets/react-native-svg/illustrations/Cloud'
import { FireIcon } from '../../assets/react-native-svg/illustrations/Fire'
import useContentWidth from '../../hooks/useContentWidth'
import { GridDisplay } from '../../components/layout/grid-display'
import { MoonIcon } from '../../assets/react-native-svg/illustrations/Moon'
import i18n from '../../i18n/i18nnext'
import { getFactoidDateString } from '../../modules/date-helpers/date-helpers'
import { BodyTextMarkdown } from '../../components/common/copy/text-body-markdown'
import { selectInterpStyleById } from '../../ducks/ui/ui'
import {
  hexFromColorString,
  i18nKeyFromColorStr,
} from '../../modules/color/color-helpers'
import { HugIcon } from '../../assets/react-native-svg/illustrations/Hug'
import { HouseIcon } from '../../assets/react-native-svg/illustrations/House'
import { BeingChasedIcon } from '../../assets/react-native-svg/illustrations/Chasing'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'

type YearlyReportTextProps = {
  report: EndOfYearWrapped
}

export default function YearlyReportText({ report }: YearlyReportTextProps) {
  // PROPS
  const { interpretationStyleId, topColor, subscriptionTier: userTier } = report

  // HOOKS
  const { t } = useTranslation()
  const { paddedContentWidth } = useContentWidth()

  // SELECTORS
  const user = useSelector(selectUser)
  const interpStyle: InterpretationStyle | undefined = useSelector(
    selectInterpStyleById(interpretationStyleId || ''),
  )

  // VARS
  const isPremium = userTier === 'premium' || userTier === 'supporter'
  const userLanguage = i18n.resolvedLanguage || 'en'
  const interpKey = interpStyle?.key || 'standard'
  // @ts-ignore
  const faveColorHex = hexFromColorString(topColor?.name || '')

  // I18N
  const userTierString = t(`eoyWrapped.tier.${userTier}`)
  const interpStyleString = t(`interpretationStyles.${interpKey}`)

  // FUNCTIONS
  const getListString = (list: Tag[] | undefined): string => {
    const nameList = [0, 1, 2].map((num) => {
      const topChars = list || []
      return topChars[num] ? topChars[num].name : ''
    })
    const listString = nameList.join(', ')
    return listString
  }

  const getTagNameAtIndex = (
    list: Tag[] | undefined,
    index: number,
  ): string => {
    const topChars = list || []
    return topChars[index] ? topChars[index].name || '' : ''
  }

  // STRING MAP
  const paragraphStrings = {
    // @ts-ignore
    eoyWrapped_top_emotions_1: getTagNameAtIndex(report.topEmotions, 0),
    // @ts-ignore
    eoyWrapped_top_emotions_2: getTagNameAtIndex(report.topEmotions, 1),
    // @ts-ignore
    eoyWrapped_top_emotions_3: getTagNameAtIndex(report.topEmotions, 2),
    // @ts-ignore
    eoyWrapped_top_animals_1: getTagNameAtIndex(report.topAnimals, 0),
    // @ts-ignore
    eoyWrapped_top_animals_2: getTagNameAtIndex(report.topAnimals, 1),
    // @ts-ignore
    eoyWrapped_top_animals_3: getTagNameAtIndex(report.topAnimals, 2),
    // @ts-ignore
    eoyWrapped_top_themes_1: t(getTagNameAtIndex(report.topThemes, 0)),
    // @ts-ignore
    eoyWrapped_top_themes_2: t(getTagNameAtIndex(report.topThemes, 1)),
    // @ts-ignore
    eoyWrapped_top_themes_3: t(getTagNameAtIndex(report.topThemes, 2)),
    // @ts-ignore
    eoyWrapped_top_othertags_1: getTagNameAtIndex(report.topOthers, 0),
    // @ts-ignore
    eoyWrapped_top_othertags_2: getTagNameAtIndex(report.topOthers, 1),
    // @ts-ignore
    eoyWrapped_top_othertags_3: getTagNameAtIndex(report.topOthers, 2),
    // @ts-ignore
    eoyWrapped_top_symbol_1: t(`symbol.${report.topSymbol?.name || ''}`),
    // @ts-ignore
    eoyWrapped_top_color_1: t(i18nKeyFromColorStr(topColor?.name || '')),
  }

  // FACTOIDS
  const basicFactoids = [
    {
      id: 'factoid-dreams-recorded',
      item: (
        <Factoid
          key={'factoid-dreams-recorded'}
          Icon={CloudIcon}
          factoidFact={`${report.numDreams}`}
          textLineOne={t('chartsPage.factoids.dreamsRecorded.line1')}
          textLineTwo={t('chartsPage.factoids.dreamsRecorded.line2')}
          layout="fact-text-text"
        />
      ),
    },
    {
      id: 'factoid-dreamiest-month',
      item: (
        <Factoid
          key={'factoid-dreamiest-month'}
          Icon={MoonIcon}
          factoidFact={getFactoidDateString(
            report.dreamiestMonth,
            2024,
            userLanguage,
          )}
          textLineOne={t('chartsPage.factoids.dreamiestMonth.line1')}
          textLineTwo={t('chartsPage.factoids.dreamiestMonth.line2')}
          layout="fact-text-text"
        />
      ),
    },
    {
      id: 'factoid-dream-streak',
      item: (
        <Factoid
          key={'factoid-dream-streak'}
          Icon={FireIcon}
          factoidFact={`${report.longestStreak}`}
          textLineOne={t('eoyWrapped.factoid3line1')}
          textLineTwo={t('eoyWrapped.factoid3line2')}
          layout="fact-text-text"
        />
      ),
    },
  ]

  const dreamTopicFactoids = [
    {
      id: 'factoid-top-characters',
      item: (
        <Factoid
          key={'factoid-top-characters'}
          Icon={HugIcon}
          // @ts-ignore
          factoidFact={getListString(report.topCharacters)}
          textLineOne={t('eoyWrapped.factoid4line1')}
          textLineTwo={t('eoyWrapped.factoid4line2')}
          layout="text-text-fact"
        />
      ),
    },
    {
      id: 'factoid-top-places',
      item: (
        <Factoid
          key={'factoid-top-places'}
          Icon={HouseIcon}
          // @ts-ignore
          factoidFact={getListString(report.topSettings)}
          textLineOne={t('eoyWrapped.factoid5line1')}
          textLineTwo={t('eoyWrapped.factoid5line2')}
          layout="text-text-fact"
        />
      ),
    },
    {
      id: 'factoid-top-actions',
      item: (
        <Factoid
          key={'factoid-top-actions'}
          Icon={BeingChasedIcon}
          // @ts-ignore
          factoidFact={getListString(report.topActions)}
          textLineOne={t('eoyWrapped.factoid6line1')}
          textLineTwo={t('eoyWrapped.factoid6line2')}
          layout="text-text-fact"
        />
      ),
    },
  ]

  return (
    <PaddedContentAreaConditional pb={16}>
      <HeadingMain mt={12} mb={8}>
        {t('eoyWrapped.header')}
      </HeadingMain>

      <BodyText mb={4}>
        {t('eoyWrapped.greeting', { username: user?.username })}
      </BodyText>
      <BodyText mb={4}>{t('eoyWrapped.p1')}</BodyText>
      <BodyText mb={4}>
        {t('eoyWrapped.p2', {
          eoyWrapped_numdreams: report.numDreams,
          eoyWrapped_toppercent: report.topPercent,
        })}
      </BodyText>
      <BodyText mb={4}>{t('eoyWrapped.p3')}</BodyText>
      {isPremium && <BodyText mb={4}>{t('eoyWrapped.p4')}</BodyText>}
      <GridDisplay
        mt={4}
        mb={6}
        maxWidth={paddedContentWidth}
        width={paddedContentWidth}
        items={basicFactoids}
      />
      <BodyText mt={4} mb={2}>
        {t('eoyWrapped.p5')}
      </BodyText>
      <GridDisplay
        mt={4}
        mb={6}
        maxWidth={paddedContentWidth}
        width={paddedContentWidth}
        items={dreamTopicFactoids}
      />
      <BodyText mb={4} fontStyle={'italic'} fontSize={'sm'}>
        {t('eoyWrapped.p9')}
      </BodyText>
      <BodyText mb={4}>
        {t('eoyWrapped.p6', paragraphStrings)}
        <BodyText style={{ color: faveColorHex }}> ▇</BodyText>
      </BodyText>
      {isPremium && (
        <BodyText mb={4}>
          {t('eoyWrapped.p7', {
            eoyWrapped_top_interp_style_1: interpStyleString,
          })}
        </BodyText>
      )}
      {isPremium && report.dreamAnalysis && (
        <BodyText mb={4}>
          {t('eoyWrapped.p8', { eoyWrapped_tier: userTierString })}
        </BodyText>
      )}
      {isPremium && report.dreamAnalysis && (
        <BodyTextMarkdown>{report.dreamAnalysis}</BodyTextMarkdown>
      )}
    </PaddedContentAreaConditional>
  )
}
