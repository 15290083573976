import React, { useState, useCallback } from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { selectUser } from '../../ducks/user/user'
import { useTranslation } from 'react-i18next'
import { AnswersTab } from './AnswersTab'
import { AskYourDreamsHeader } from './header'
import { useFocusEffect } from '@react-navigation/native'
import { Insight, InsightService } from '../../../../api/_openapi'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import { HeadingSans } from '../../components/common/copy/heading-sans'
import { PaddedContentAreaConditional } from '../../components/layout/content-area-padded-conditional'
import {
  MainStackNavigationProp,
  MainStackScreenProps,
} from '../../navigation/types'
import useContentWidth from '../../hooks/useContentWidth'

export type AskYourDreamsNavigationProp =
  MainStackNavigationProp<'PrivateAskYourDreams'>

export default function AskYourDreams({
  route,
}: MainStackScreenProps<'PrivateAskYourDreams'>) {
  // STATE
  const [insights, setInsights] = useState<Insight[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  // HOOKS
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const { contentWidth } = useContentWidth()

  // EFFECTS
  useFocusEffect(
    useCallback(() => {
      if (user) {
        InsightService.dreamSeriesInsights()
          .then((insights) => {
            setInsights(insights)
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            setLoading(false)
          })
      }
      return
    }, [user]),
  )

  return (
    <ScrollView
      contentContainerStyle={{
        width: '100%',
        maxWidth: contentWidth,
        marginHorizontal: 'auto',
      }}
    >
      <AskYourDreamsHeader insights={insights} setInsights={setInsights} />
      <PaddedContentAreaConditional>
        <HeadingSans textAlign={'center'}>
          {t('askYourDreams.tabLabel')}
        </HeadingSans>
      </PaddedContentAreaConditional>
      <AnswersTab
        route={route}
        loading={loading}
        insights={insights}
        noInsightsMsg={t('askYourDreams.noInsightsMessage')}
        setInsights={setInsights}
      />
    </ScrollView>
  )
}
