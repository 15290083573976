import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BodyText } from '../../../components/common/copy/text-body'
import { useSelector } from '../../../ducks/root-reducer'
import Markdown from '@ronradtke/react-native-markdown-display'
import { useColorMode } from 'native-base'
import { SansText } from '../../../components/common/copy/text-sans'
import {
  FastDream,
  FastDreamService,
  FastTag,
  FastTagService,
} from '../../../../../api/_openapi'
import { useDispatch } from 'react-redux'
import { addFastDream } from '../../../ducks/dream-tag/dream-tag'
import { parseFile, validateCsvFile } from './functions'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { HighlightBox } from '../../../components/layout/highlight-box'
import useContentWidth from '../../../hooks/useContentWidth'
import { PaddedContentAreaConditional } from '../../../components/layout/content-area-padded-conditional'
import { ProgressBar } from '../../../components/common/progress-bar/progress-bar'
import { json2csv } from 'json-2-csv'
import {
  RootStackNavigationProp,
  RootStackScreenProps,
} from '../../../navigation/types'
import {
  LARGE_LINE_HEIGHT,
  LG_FONTSIZE_PIXELS,
} from '../../../constants/constants'
import './data-import.css'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'
import { uuidv7 } from 'uuidv7'
import { useAuth } from '../../../contexts/auth-context'
import { Row } from '../../../components/common/row/row'
import { Column } from '../../../components/common/column/column'
import { ScrollView } from '../../../components/common/scrollview/scrollview'
import {
  selectLucidDreamTypeTag,
  selectNightmareDreamTypeTag,
} from '../../../ducks/ui/ui'
import { undecorateId } from '../../../../../api/decorate-ids'
import { formatDateTimeForDatabase } from '../../../modules/strings/string-helpers'

export type SpreadsheetDream = {
  id: string
  title: string
  date: string
  dream: string
  note: string
  nightmare: boolean
  lucid: boolean
  date_format: string
}

export type UploadableDream = Partial<FastDream> & {
  isLucid: boolean
  isNightmare: boolean
}

// Allowed column names (keys in UploadDream)
export const allowedColumns: (keyof SpreadsheetDream)[] = [
  'id',
  'title',
  'date',
  'dream',
  'note',
  'nightmare',
  'lucid',
  'date_format',
]

export const requiredColumns: (keyof SpreadsheetDream)[] = ['date', 'dream']

export type DataImportNavigationProp =
  RootStackNavigationProp<'PrivateDataImport'>

// Page to import data
export default function DataImport({
  navigation,
  route,
}: RootStackScreenProps<'PrivateDataImport'>) {
  // STATE
  const [file, setFile] = useState<File | null>(null)
  const [parsedData, setParsedData] = useState<UploadableDream[] | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [dataWithErrors, setDataWithErrors] = useState<UploadableDream[]>([])

  // HOOKS
  const { t } = useTranslation()
  const { colorMode } = useColorMode()
  const dispatch = useDispatch<any>()
  const { contentWidth } = useContentWidth()
  const { authData } = useAuth()

  // SELECTORS
  const privateGroupId = useSelector(selectPrivateGroupId)
  const lucidDreamTag = useSelector(selectLucidDreamTypeTag)
  const nightmareDreamTag = useSelector(selectNightmareDreamTypeTag)

  // VARS
  const hasParsedRows = Boolean(parsedData?.length)
  const userId = authData?.user
  const uploadingFinished = progress && progress === parsedData?.length
  const shouldShowErrors = dataWithErrors.length > 0 && uploadingFinished

  // CHOOSE FILE
  const onChooseFile = (file: File) => {
    const fileIsValid = validateCsvFile(file)
    if (fileIsValid) {
      setFile(file)
      parseFile(file, (result) => {
        console.log('Parsed data:', result)

        setParsedData(result)
      })
    }
  }

  // FUNCTIONS
  const onUploadPress = useCallback(
    async (parsedData: UploadableDream[], userId: string | undefined) => {
      if (!userId) return

      setProgress(0)
      setIsUploading(true)

      // Scroll to bottom of page
      window.scrollTo(0, document.body.scrollHeight)

      const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms))

      for (let i = 0; i < parsedData.length; i++) {
        const dream = parsedData[i]
        const savedDream = await saveDreamToDb(
          dream,
          userId,
          privateGroupId,
          lucidDreamTag,
          nightmareDreamTag,
          privateGroupId,
        )
        if (savedDream) {
          dispatch(addFastDream(savedDream))
        } else {
          // If the dream failed to save, add it to the list of dreams with errors
          setDataWithErrors((prevData) => [...prevData, dream])
        }
        setProgress(i + 1)
        await delay(200) // Wait before proceeding to the next iteration
      }
      setIsUploading(false)
      elsewhereToast.showToast({
        description: 'toast.journal.emailadded.title',
        status: 'success',
      })
    },
    [dispatch, userId],
  )

  return (
    <ScrollView width="100%" height="100%">
      <PaddedContentAreaConditional>
        {['importDataPage.description', 'importDataPage.description2'].map(
          (paragraph, index) => {
            return (
              <BodyText mt={4} key={index}>
                {t(paragraph)}
              </BodyText>
            )
          },
        )}
        <BodyText>
          <Markdown
            style={{
              body: {
                fontFamily: 'OptimaLTPro-Roman',
                fontWeight: '400',
                fontSize: LG_FONTSIZE_PIXELS,
                lineHeight: LARGE_LINE_HEIGHT,
                // color,
                marginBottom: 12,
              },
              // Change bg color of code blocks
              codeBlock: {
                backgroundColor: '#f00',
                fontFamily: 'monospace',
              },
              heading2: {
                // fontSize: 55,
              },
            }}
          >
            {t('importDataPage.detailedDescription')}
          </Markdown>
        </BodyText>
      </PaddedContentAreaConditional>
      <HighlightBox
        width={contentWidth}
        marginBottom={10}
        padding={6}
        mx="auto"
      >
        <form
          style={{
            width: '100%',
          }}
        >
          <label
            htmlFor="file-upload"
            className={`upload-page-btn ${colorMode}`}
            style={{
              width: 'calc(100% - 48px)',
              cursor: 'pointer',
              textAlign: 'center',
              display: 'block',
            }}
          >
            {t('common.chooseFile')}
          </label>
          <input
            style={{ display: 'none' }}
            id="file-upload"
            type="file"
            onChange={(e) => {
              if (e.target.files) {
                onChooseFile(e.target.files[0])
              }
            }}
          />
        </form>
        {parsedData && hasParsedRows && (
          <Row justifyContent={'center'} alignItems={'center'} mb={6} mt={6}>
            <SansText>
              {file?.name}:{' '}
              {t('importDataPage.numDreamsFound', {
                count: parsedData.length,
              })}
            </SansText>
          </Row>
        )}
        <SansText></SansText>
        {hasParsedRows && (
          <input
            className={`upload-page-btn ${colorMode}`}
            type="submit"
            style={{ width: '100%' }}
            value={t('common.upload')}
            onClick={() => {
              if (parsedData) {
                onUploadPress(parsedData, userId)
              }
            }}
          />
        )}
        {parsedData && (progress > 0 || isUploading) && (
          <ProgressBar total={parsedData.length} progress={progress} mt={6} />
        )}
        {/* ALLOW DOWNLOAD OF ROWS WITH PROBLEMS */}
        {shouldShowErrors && (
          <Column>
            <SansText mt={6}>{t('errors.uploadDreamError')}</SansText>
            {dataWithErrors.map((row, index) => {
              return (
                <Row key={index} mt={2}>
                  <SansText>{`${row.title} (${row.date})`}</SansText>
                </Row>
              )
            })}
            <input
              className={`upload-page-btn ${colorMode}`}
              type="submit"
              style={{ width: '100%', marginTop: 12 }}
              value={t('common.upload')}
              onClick={() => {
                // Make a CSV file from the rows with errors
                // Convert the data to CSV
                const csv = json2csv(dataWithErrors)
                // Create a URL for the CSV file
                const url = window.URL.createObjectURL(new Blob([csv]))
                const a = document.createElement('a')
                a.href = url
                a.download = 'dreams-with-errors.csv'
                a.click()
              }}
            />
          </Column>
        )}
      </HighlightBox>
    </ScrollView>
  )
}

async function saveDreamToDb(
  dream: UploadableDream,
  userId: string,
  groupId: string | undefined,
  lucidDreamTag: FastTag | undefined,
  nightmareDreamTag: FastTag | undefined,
  privateGroupId: string | undefined,
): Promise<FastDream | null> {
  if (!groupId) {
    return null
  }

  // Make an id for the dream
  const id = uuidv7()

  // Make a DreamCreateType from the UploadDream
  const dreamCreate: Partial<UploadableDream> = {
    id: id,
    date: dream.date,
    description: dream.description,
    isDraft: false,
    note: dream.note || '',
    title: dream.title || '',
    type: 'dream',
    displayDate: undefined,
    userId: userId,
  }
  const savedDream: FastDream | void = await FastDreamService.groupDreamCreate(
    groupId,
    dreamCreate,
    false,
    true,
  ).catch((error) => {
    console.log('Error saving dream:', error)
  })

  // Add lucid and nightmare tags if necessary
  if (savedDream && (dream.isLucid || dream.isNightmare)) {
    const { id: dreamId } = savedDream
    const groupId = privateGroupId || ''
    // Get the dream
    await FastTagService.dreamTags(undecorateId(dreamId)).then((tags) => {
      // Make a list of tags to add
      let tagsToAdd = [...tags]
      if (dream.isLucid && lucidDreamTag) {
        tagsToAdd.push(lucidDreamTag)
      }
      if (dream.isNightmare && nightmareDreamTag) {
        tagsToAdd.push(nightmareDreamTag)
      }
      FastDreamService.groupDreamUpdate(groupId, dreamId, {
        ...savedDream,
        tags: tagsToAdd,
        updatedAt: formatDateTimeForDatabase(new Date()),
      }).catch((error) => {
        console.log('Error updating dream:', error)
      })
    })
  }

  if (savedDream) {
    return savedDream
  } else {
    return null
  }
}
