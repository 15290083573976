import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AuthData, authService } from '../services/auth-service'
import { useDispatch } from 'react-redux'
import { resetUserState } from '../ducks/user/user'
import { deactivateNotifications } from '../services/notification-service'
import { resetDreamTagState } from '../ducks/dream-tag/dream-tag'
import { persistor } from '../ducks/store'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { decode } from 'base-64'
import packageInfoMobile from '../../../mobile/package.json'
import packageInfoWeb from '../../../web/package.json'
import { setLastSignInVersion } from '../ducks/ui/ui'
import i18n from '../i18n/i18nnext'
import { languageDetector } from '../i18n/i18nnext.web'
import { getDeviceLanguageCode } from '../modules/language-helpers/language-helpers'

global.atob = decode

type AuthContextData = {
  authData?: AuthData
  loading: boolean
  sendPasscode(email: string): Promise<boolean>
  signIn(
    email: string,
    passcode: string,
    inviteCode?: string,
  ): Promise<AuthData | undefined>
  refreshToken(oldToken: string): Promise<AuthData | undefined>
  signOut(): void
}

// Create the Auth Context with the data type specified and an empty object
const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData | undefined>()

  const dispatch = useDispatch()

  // Start the AuthContext start with loading equals true until data is loaded
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Every time the App is opened, this provider is rendered and loadStorage is called
    loadStorageData()
  }, [])

  async function loadStorageData(): Promise<void> {
    try {
      // Get the data from Async Storage
      const authDataSerialized = await AsyncStorage.getItem('@AuthData')
      if (authDataSerialized) {
        // If there is data, convert to object and update state
        const _authData: AuthData = JSON.parse(authDataSerialized)
        try {
          await refreshToken(_authData.token)
        } catch (error) {
          signOut()
        }
        return
      } else {
        return undefined
      }
    } catch (error) {
      // If there is an error, log it
      console.log('Error loading AuthData from AsyncStorage:', error)
    } finally {
      // Finish loading
      setLoading(false)
    }
  }

  const signIn = async (
    email: string,
    passcode: string,
    inviteCode?: string,
  ) => {
    // Call the service with credentials
    const _authData = await authService.signIn(email, passcode)

    // if nothing returned return result
    if (!_authData) {
      return _authData
    }
    // Set the data in the context, so the App sends the user to the authed stack
    console.log('signIn', _authData)
    setAuthData(_authData)
    // Set last sign in version
    dispatch(
      setLastSignInVersion(
        Platform.OS === 'web'
          ? packageInfoWeb?.version
          : packageInfoMobile?.version,
      ),
    )
    // Persist the data in Async Storage to be recovered in the next user session.
    AsyncStorage.setItem('@AuthData', JSON.stringify(_authData))

    if (inviteCode) {
      AsyncStorage.setItem('@InviteCode', inviteCode)
    }

    // return result if successful
    return _authData
  }

  const signOut = async () => {
    await deactivateNotifications()
    await dispatch(resetDreamTagState())
    await dispatch(resetUserState())
    await persistor.purge()
    setAuthData(undefined)
    // Remove the data from Async Storage to NOT be recovered in next session.
    //await AsyncStorage.removeItem('@AuthData')
    try {
      await AsyncStorage.clear()
    } catch (error) {
      console.log('Error clearing AsyncStorage:', error)
    }

    const subDomainLanguages =
      Platform.OS === 'web' && languageDetector.detect()
    const subDomainLanguage =
      subDomainLanguages && Array.isArray(subDomainLanguages)
        ? subDomainLanguages[0]
        : subDomainLanguages
    const deviceLanguage = getDeviceLanguageCode()
    if (subDomainLanguage && Platform.OS === 'web') {
      document.documentElement.setAttribute('lang', subDomainLanguage)
    }
    i18n.changeLanguage((subDomainLanguage as string) || deviceLanguage || 'en')
  }

  const sendPasscode = async (email: string) => {
    return await authService.sendPasscode(email)
  }

  const refreshToken = async (oldToken: string) => {
    // Call the service with credentials
    const _authData = await authService.refreshToken(oldToken)

    if (!_authData) {
      return undefined
    }

    // Set the data in the context, so the App sends the user to the authed stack
    setAuthData(_authData)

    // Persist the data in Async Storage to be recovered in the next user session.
    AsyncStorage.setItem('@AuthData', JSON.stringify(_authData))

    // return result if successful
    return _authData
  }

  return (
    // This component is used to encapsulate the App, so all components have access to the Context
    <AuthContext.Provider
      value={{ authData, loading, signIn, signOut, sendPasscode, refreshToken }}
    >
      {children}
    </AuthContext.Provider>
  )
}

// A simple hook to facilitate access to the AuthContext and permit components to subscribe to AuthContext updates
function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { AuthContext, AuthProvider, useAuth }
