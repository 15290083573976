import { getLocales } from 'react-native-localize'
import { SUPPORTED_LANGUAGES } from '../../i18n/config'
import {
  BODY_FONT,
  BODY_FONT_ARABIC,
  BODY_FONT_ARABIC_BOLD,
  BODY_FONT_BOLD,
  BODY_FONT_CYRILLIC,
  BODY_FONT_CYRILLIC_BOLD,
  BODY_FONT_ITALIC,
  canonicalLocaleCodes,
  EXTRA_SMALL_FONT_SIZE,
  EXTRA_SMALL_LINE_HEIGHT_ARABIC_IOS,
  HEADING_FONT,
  HEADING_FONT_ARABIC,
  HEADING_FONT_CYRILLIC,
  LARGE_FONT_SIZE,
  LARGE_LINE_HEIGHT_ARABIC_IOS,
  MEDIUM_FONT_SIZE,
  MEDIUM_LINE_HEIGHT_ARABIC_IOS,
  SANS_BOOK_FONT,
  SANS_BOOK_FONT_ARABIC,
  SANS_BOOK_FONT_CYRILLIC,
  SMALL_FONT_SIZE,
  SMALL_LINE_HEIGHT_ARABIC_IOS,
} from '../../constants/constants'
import { FastTag } from '../../../../api/_openapi'
import { ScriptType, SupportedLanguage } from '../../../../api/frontend-types'
import { Size } from '../../core/theme'

// Get the language code of the device
// (User may have multiple in settings, but we just want the first)
export const getDeviceLanguageCode = (): string => {
  const locales = getLocales()
  // Get language from subdomain
  const subdomainLang = (window?.location?.hostname || '').split('.')[0]
  if (isSupportedLanguage(subdomainLang)) {
    return subdomainLang
  } else if (!locales || locales.length === 0) {
    return 'en'
  } else {
    return locales[0].languageCode
  }
}

// E.g. en-US -> true, ar-EG -> true, en-EG -> false
export function isValidLanguageLocaleCode(code: string): boolean {
  try {
    // Check if Intl.DateTimeFormat supports the given locale
    const formatter = new Intl.DateTimeFormat(code)
    // If the resolved locale matches the input, it's supported
    return formatter.resolvedOptions().locale === code
  } catch (e) {
    // An error here means the locale is invalid or unsupported
    return false
  }
}

// We might try to guess the user's language locale from their
// language code + locale. But we need to make sure it's a valid
// E.g. Australian English is fine, but Australian Arabic is not
// So e.g. ar-AU -> ar-SA
export function validifyLanguageLocaleCode(code: string): string {
  // Case 1) If it's a valid locale code, return it
  if (isValidLanguageLocaleCode(code)) {
    return code
  }
  // Case 2) If it's not a language code, return 'en-US'
  else if (!code.includes('-')) {
    return 'en-US'
  }

  const languageCode = code.split('-')[0]
  const isSupportedLang = isSupportedLanguage(languageCode)

  // Case 3) If it's a supported language in a weird locale, return the canonical locale
  if (isSupportedLang) {
    const langCode: SupportedLanguage = languageCode as SupportedLanguage
    const validLocaleCode = canonicalLocaleCodes[langCode]
    return validLocaleCode
  }
  // Case 4) If it's not a supported language, return 'en-US'
  else {
    return 'en-US'
  }
}

export function isSupportedLanguage(languageCode: string | undefined): boolean {
  if (!languageCode) {
    return false
  }
  return SUPPORTED_LANGUAGES.includes(languageCode)
}

export function isRtlLanguage(languageCode: string): boolean {
  return ['ar', 'fa', 'he'].includes(languageCode)
}

export function isCyrillicLanguage(languageCode: string): boolean {
  return ['ru', 'uk', 'el'].includes(languageCode)
}

// Get a suffix for fonts based on the language
export function getFontSuffix(languageCode: string): string {
  switch (languageCode) {
    case 'fa':
      return 'Arabic'
    case 'ar':
      return 'Arabic'
    case 'ru':
      return 'Cyrillic'
    case 'uk':
      return 'Cyrillic'
    case 'el':
      return 'Cyrillic'
    default:
      return ''
  }
}

function getFontSuffixForScriptType(scriptType: ScriptType): string {
  switch (scriptType) {
    case 'arabic':
      return 'Arabic'
    case 'cyrillic':
      return 'Cyrillic'
    default:
      return ''
  }
}

export function getSansFont(
  languageCode: string,
  scriptType?: ScriptType,
): string {
  // If scripttype (arabic, latin) is provided, use that
  if (scriptType) {
    switch (scriptType) {
      case 'arabic':
        return SANS_BOOK_FONT_ARABIC
      case 'cyrillic':
        return SANS_BOOK_FONT_CYRILLIC
      default:
        return SANS_BOOK_FONT
    }
  }

  // Else, use the language code
  switch (languageCode) {
    case 'fa':
      return SANS_BOOK_FONT_ARABIC
    case 'ar':
      return SANS_BOOK_FONT_ARABIC
    case 'ru':
      return SANS_BOOK_FONT_CYRILLIC
    case 'uk':
      return SANS_BOOK_FONT_CYRILLIC
    case 'el':
      return SANS_BOOK_FONT_CYRILLIC
    default:
      return SANS_BOOK_FONT
  }
}

// i.e. 'sans' or 'sansArabic'
export function getSansFontKey(
  languageCode: string,
  scriptType?: ScriptType,
): string {
  // If scripttype (arabic, latin) is provided, use that
  if (scriptType) {
    const suffix = getFontSuffixForScriptType(scriptType)
    return `sans${suffix}`
  }
  // Else, use the language code
  else {
    const suffix = getFontSuffix(languageCode)
    return `sans${suffix}`
  }
}

export const getHeadingFontKey = (
  languageCode: string,
  scriptType?: ScriptType,
): string => {
  // If scripttype (arabic, latin) is provided, use that
  if (scriptType) {
    const suffix = getFontSuffixForScriptType(scriptType)
    return `heading${suffix}`
  }
  // Else, use the language code
  else {
    const suffix = getFontSuffix(languageCode)
    return `heading${suffix}`
  }
}

export function getBodyFont(
  languageCode: string,
  style?: 'bold' | 'italic' | 'normal',
): string {
  switch (languageCode) {
    case 'fa':
      return style === 'bold' ? BODY_FONT_ARABIC_BOLD : BODY_FONT_ARABIC
    case 'ar':
      return style === 'bold' ? BODY_FONT_ARABIC_BOLD : BODY_FONT_ARABIC
    case 'ru':
      return style === 'bold' ? BODY_FONT_CYRILLIC_BOLD : BODY_FONT_CYRILLIC
    case 'uk':
      return style === 'bold' ? BODY_FONT_CYRILLIC_BOLD : BODY_FONT_CYRILLIC
    case 'el':
      return style === 'bold' ? BODY_FONT_CYRILLIC_BOLD : BODY_FONT_CYRILLIC
    default:
      return style === 'bold'
        ? BODY_FONT_BOLD
        : style === 'italic'
        ? BODY_FONT_ITALIC
        : BODY_FONT
  }
}

export function getHeadingFont(languageCode: string): string {
  switch (languageCode) {
    case 'fa':
      return HEADING_FONT_ARABIC
    case 'ar':
      return HEADING_FONT_ARABIC
    case 'ru':
      return HEADING_FONT_CYRILLIC
    case 'uk':
      return HEADING_FONT_CYRILLIC
    case 'el':
      return HEADING_FONT_CYRILLIC
    default:
      return HEADING_FONT
  }
}

export const getScriptTypeForLanguage = (languageCode?: string): ScriptType => {
  switch (languageCode) {
    case 'ar':
      return 'arabic'
    case 'fa':
      return 'arabic'
    case 'ru':
      return 'cyrillic'
    case 'uk':
      return 'cyrillic'
    case 'el':
      return 'cyrillic'
    default:
      return 'latin'
  }
}

export function getLanguageForTag(tag: FastTag, userLanguage: string): string {
  const tagType = tag.type

  switch (tagType) {
    case 'THEME':
      return userLanguage
    case 'SYMBOL':
      return userLanguage
    case 'DREAM_TYPE':
      return userLanguage
    default:
      return tag.languageCode || userLanguage
  }
}

export function iosLineHeightForFontsizeArabic(fs: number | Size): number {
  const xs = typeof fs === 'string' ? fs === 'xs' : fs <= EXTRA_SMALL_FONT_SIZE
  const sm = typeof fs === 'string' ? fs === 'sm' : fs <= SMALL_FONT_SIZE
  const md = typeof fs === 'string' ? fs === 'md' : fs <= MEDIUM_FONT_SIZE
  const lg = typeof fs === 'string' ? fs === 'lg' : fs <= LARGE_FONT_SIZE

  if (xs) {
    return EXTRA_SMALL_LINE_HEIGHT_ARABIC_IOS
  } else if (sm) {
    return SMALL_LINE_HEIGHT_ARABIC_IOS
  } else if (md) {
    return MEDIUM_LINE_HEIGHT_ARABIC_IOS
  } else if (lg) {
    return LARGE_LINE_HEIGHT_ARABIC_IOS
  } else {
    return LARGE_LINE_HEIGHT_ARABIC_IOS
  }
}

// Take a language code and decide if it's a variety of French
export function isFrench(languageCode: string): boolean {
  return languageCode === 'fr' || languageCode.startsWith('fr-')
}

export function isEnglish(languageCode: string): boolean {
  // If the code starts with 'en', it's English
  return languageCode === 'en' || languageCode.startsWith('en-')
}

export function isGerman(languageCode: string): boolean {
  // If the code starts with 'de', it's German
  return languageCode === 'de' || languageCode.startsWith('de-')
}

export function isHungarian(languageCode: string): boolean {
  // If the code starts with 'hu', it's Hungarian
  return languageCode === 'hu' || languageCode.startsWith('hu-')
}

export function isJapanese(languageCode: string): boolean {
  // If the code starts with 'ja', it's Japanese
  return languageCode === 'ja' || languageCode.startsWith('ja-')
}

export function isPolish(languageCode: string): boolean {
  // If the code starts with 'pl', it's Polish
  return languageCode === 'pl' || languageCode.startsWith('pl-')
}
