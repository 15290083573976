import React from 'react'
import i18n from '../../../i18n/i18nnext'
import { useColorModeValue } from 'native-base'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import Markdown, {
  MarkdownProps,
} from '@ronradtke/react-native-markdown-display'
import {
  getBodyFont,
  getHeadingFont,
  isRtlLanguage,
} from '../../../modules/language-helpers/language-helpers'
import {
  BODY_FONT_BOLD_WEIGHT_STR,
  BODY_FONT_WEIGHT_STR,
  HEADER_FONT_WEIGHT_STR,
  LARGE_LINE_HEIGHT,
  LG_FONTSIZE_PIXELS,
  LG_LINE_HEIGHT,
  XL_FONTSIZE_PIXELS,
  XL_LINE_HEIGHT,
  XXL_FONTSIZE_PIXELS,
  XXL_LINE_HEIGHT,
} from '../../../constants/constants'

export type BodyTextMarkdownProps = Omit<MarkdownProps, 'children'> & {
  color?: string
  textAlign?: 'left' | 'center' | 'right'
  children?: string | string[]
  language?: SupportedLanguage
}

export const BodyTextMarkdown = ({
  color,
  children = '',
  style,
  language,
  ...rest
}: BodyTextMarkdownProps) => {
  const defaultTextColor = useColorModeValue(BLACK, WHITE)
  const textColor = color || defaultTextColor
  const childrenAsString = `${children}`

  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const bodyFontFamily = getBodyFont(lang)
  const bodyFontFamilyItalic = getBodyFont(lang, 'italic')
  const bodyFontFamilyBold = getBodyFont(lang, 'bold')
  const headerFontFamily = getHeadingFont(lang)
  const textAlign = isRtlLanguage(lang) ? 'right' : 'left'

  return (
    <Markdown
      style={{
        body: {
          fontFamily: bodyFontFamily,
          fontWeight: BODY_FONT_WEIGHT_STR,
          fontSize: LG_FONTSIZE_PIXELS,
          lineHeight: LARGE_LINE_HEIGHT,
          color: textColor,
          textAlign: textAlign,
          marginTop: -10,
          marginBottom: -10,
        },
        strong: {
          fontFamily: bodyFontFamilyBold,
          fontWeight: BODY_FONT_BOLD_WEIGHT_STR,
        },
        em: {
          fontFamily: bodyFontFamilyItalic,
          fontWeight: BODY_FONT_WEIGHT_STR,
        },
        heading2: {
          fontFamily: headerFontFamily,
          fontWeight: HEADER_FONT_WEIGHT_STR,
          textTransform: 'uppercase',
          fontSize: XXL_FONTSIZE_PIXELS,
          lineHeight: XXL_LINE_HEIGHT,
          textAlign: 'center',
          marginTop: 24,
          marginBottom: 16,
          width: '100%',
          justifyContent: 'center',
        },
        heading3: {
          fontFamily: headerFontFamily,
          fontWeight: HEADER_FONT_WEIGHT_STR,
          textTransform: 'uppercase',
          fontSize: XL_FONTSIZE_PIXELS,
          lineHeight: XL_LINE_HEIGHT,
          textAlign: 'center',
          marginTop: 32,
          marginBottom: 16,
          width: '100%',
          justifyContent: 'center',
        },
        heading4: {
          fontFamily: headerFontFamily,
          fontWeight: HEADER_FONT_WEIGHT_STR,
          textTransform: 'uppercase',
          fontSize: LG_FONTSIZE_PIXELS,
          lineHeight: LG_LINE_HEIGHT,
          textAlign: 'center',
          marginTop: 16,
          marginBottom: 16,
          justifyContent: 'center',
        },
        ...style,
      }}
      {...rest}
    >
      {childrenAsString}
    </Markdown>
  )
}
