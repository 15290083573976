/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastDream } from '../models/FastDream';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastDreamService {
  /**
   * Creates a FastDream resource.
   * Creates a FastDream resource.
   * @param name The name of the collection
   * @param id FastDream identifier
   * @param requestBody The new FastDream resource
   * @returns FastDream FastDream resource created
   * @throws ApiError
   */
  public static addDreamToPublicCollection(
    name: string,
    id: string,
    requestBody: Partial<FastDream>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<FastDream> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/collections/{name}/dreams',
      path: {
        'name': name,
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the FastDream resource.
   * Removes the FastDream resource.
   * @param name FastDream identifier
   * @param dreamId FastDream identifier
   * @returns void
   * @throws ApiError
   */
  public static removeDreamFromPublicCollection(
    name: string,
    dreamId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/collections/{name}/dreams/{dreamId}',
      path: {
        'name': name,
        'dreamId': dreamId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of FastDream resources.
   * Retrieves the collection of FastDream resources.
   * @param id FastDream identifier
   * @param tagId
   * @param updatedAfter
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastDream FastDream collection
   * @throws ApiError
   */
  public static groupDreams(
    id: string,
    tagId?: string,
    updatedAfter?: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastDream>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/groups/{id}/dreams',
      path: {
        'id': id,
      },
      query: {
        'tagId': tagId,
        'updatedAfter': updatedAfter,
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Creates a FastDream resource.
   * Creates a FastDream resource.
   * @param id FastDream identifier
   * @param requestBody The new FastDream resource
   * @param addImage
   * @param addTags
   * @returns FastDream FastDream resource created
   * @throws ApiError
   */
  public static groupDreamCreate(
    id: string,
    requestBody: Partial<FastDream>, // Modified by scripts/modifyOpenApi.js
    addImage?: boolean,
    addTags?: boolean,
  ): CancelablePromise<FastDream> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/groups/{id}/dreams',
      path: {
        'id': id,
      },
      query: {
        'addImage': addImage,
        'addTags': addTags,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Retrieves a FastDream resource.
   * Retrieves a FastDream resource.
   * @param id FastDream identifier
   * @param dreamId FastDream identifier
   * @returns FastDream FastDream resource
   * @throws ApiError
   */
  public static groupDream(
    id: string,
    dreamId: string,
  ): CancelablePromise<FastDream> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/groups/{id}/dreams/{dreamId}',
      path: {
        'id': id,
        'dreamId': dreamId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Replaces the FastDream resource.
   * Replaces the FastDream resource.
   * @param id FastDream identifier
   * @param dreamId FastDream identifier
   * @param requestBody The updated FastDream resource
   * @returns FastDream FastDream resource updated
   * @throws ApiError
   */
  public static groupDreamUpdate(
    id: string,
    dreamId: string,
    requestBody: Partial<FastDream>, // Modified by scripts/modifyOpenApi.js
  ): CancelablePromise<FastDream> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/groups/{id}/dreams/{dreamId}',
      path: {
        'id': id,
        'dreamId': dreamId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the FastDream resource.
   * Removes the FastDream resource.
   * @param id FastDream identifier
   * @param dreamId FastDream identifier
   * @returns void
   * @throws ApiError
   */
  public static groupDreamDelete(
    id: string,
    dreamId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/groups/{id}/dreams/{dreamId}',
      path: {
        'id': id,
        'dreamId': dreamId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of FastDream resources.
   * Retrieves the collection of FastDream resources.
   * @param id FastDream identifier
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastDream FastDream collection
   * @throws ApiError
   */
  public static getPathDreams(
    id: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastDream>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/paths/{id}/dreams',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Retrieves the collection of FastDream resources.
   * Retrieves the collection of FastDream resources.
   * @param id FastDream identifier
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns FastDream FastDream collection
   * @throws ApiError
   */
  public static publicCollectionDreams(
    id: string,
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<FastDream>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/collections/{name}/dreams',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Retrieves a FastDream resource.
   * Retrieves a FastDream resource.
   * @param name FastDream identifier
   * @param dreamId FastDream identifier
   * @returns FastDream FastDream resource
   * @throws ApiError
   */
  public static getDreamInPublicCollection(
    name: string,
    dreamId: string,
  ): CancelablePromise<FastDream> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/public/collections/{name}/dreams/{dreamId}',
      path: {
        'name': name,
        'dreamId': dreamId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
