import React from 'react'
import {
  CREAM,
  LIGHT_BLACK,
  WHITE,
  BLACK,
} from '../../../constants/ui-constants'
import { useColorModeValue } from 'native-base'
import { Avatar, Text } from 'native-base'
import initials from 'initials'
import { Size, avatarAndIconButtonSizes } from '../../../core/theme'
import { IconEdit } from '../../../assets/react-native-svg/icons/icons-ui'

export const abbr = (name: string, noUpperCase: any) => {
  let abbr = initials(name, noUpperCase)
  if (name.startsWith('+')) {
    abbr = `+${abbr}`
  }
  if (!abbr) {
    console.warn('Could not get abbr from name')
    abbr = name
  }
  if (abbr.length > 2) {
    abbr = abbr.substring(0, 2)
  }
  if (!noUpperCase) {
    abbr = abbr.toUpperCase()
  }
  return abbr
}

const BoringAvatar = ({
  name = 'Elsewhere',
  avatarSize,
  showEditIcon = false,
}: {
  name: string
  avatarSize: Size
  showEditIcon?: boolean
}) => {
  const color = useColorModeValue(BLACK, WHITE)
  const backgroundColor = useColorModeValue(CREAM, LIGHT_BLACK)
  const boringAvatarSize = avatarAndIconButtonSizes[avatarSize].width * 4

  return (
    <Avatar
      backgroundColor={backgroundColor}
      color={color}
      size={avatarSize}
      borderRadius="full"
    >
      <Text
        fontSize={boringAvatarSize / 2}
        lineHeight={boringAvatarSize / 2}
        fontWeight={300}
        fontFamily={'AlbertusMTPro-Light'}
        style={{ paddingTop: boringAvatarSize / 8 }}
      >
        {abbr(
          name
            .split(/\s/g)
            .map((word) => word[0])
            .join(''),
          false,
        )}
      </Text>
      {/* {showEditIcon && boringAvatarSize > 80 && <IconEdit />} */}
    </Avatar>
  )
}

export default BoringAvatar
