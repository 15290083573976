import React, { useState, useEffect } from 'react'
import { PaddedContentArea } from '../../components/layout/content-area-padded'
import { RootStackNavigationProp } from '../../navigation/types'
import { useSelector } from '../../ducks/root-reducer'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import { SansText } from '../../components/common/copy/text-sans'
import { UserStats, UserStatsService } from '../../../../api/_openapi'
import { selectIsElsewhereTeam } from '../../ducks/user/user'
import { AboutSection } from '../../../../api/frontend-types'
import { AboutList } from '../../components/composite/about-list/about-list'
import { HeadingMain } from '../../components/common/copy/heading-main'
import { ButtonIcon } from '../../components/common/buttons/button-icon'

export type AboutElsewhereTeamNavigationProp =
  RootStackNavigationProp<'PrivateAboutElsewhereTeam'>

export default function Admin() {
  // STATE
  const [userStats, setUserStats] = useState<UserStats | null>(null)
  const [aboutSection, setAboutSection] = useState<AboutSection | null>(null)

  // SELECTORS
  const isElsewhereTeam = useSelector(selectIsElsewhereTeam)

  // Log stats
  useEffect(() => {
    if (!isElsewhereTeam) {
      return
    } else {
      getStats()
    }
  }, [isElsewhereTeam])

  function getStats() {
    UserStatsService.userStats()
      .then((stats: UserStats[]) => {
        const today = new Date().toISOString().slice(0, 10)
        console.log('----------------------------------------------------')
        console.log(`USER STATS, ${today} (Only visible internally)`)
        console.log('----------------------------------------------------')
        console.log('User stats', stats)
        console.log('----------------------------------------------------')

        // Set user stats
        // @ts-ignore
        setUserStats(stats)
        setAboutSection(userStatsToAboutSection(stats))
      })
      .catch((error) => {
        console.log('Error fetching user stats', error)
      })
  }

  if (!isElsewhereTeam) {
    return (
      <ScrollView>
        <PaddedContentArea>
          <SansText>Unauthorized</SansText>
        </PaddedContentArea>
      </ScrollView>
    )
  }

  return (
    <ScrollView
      style={{
        width: '100%',
      }}
    >
      <PaddedContentArea alignItems={'center'}>
        <HeadingMain mt={4}>Admin</HeadingMain>
        <SansText>Only visible internally</SansText>
        <ButtonIcon
          mt={4}
          iconKey={'refresh'}
          onPress={() => {
            setAboutSection(null)
            setUserStats(null)
            getStats()
          }}
        />
        {aboutSection && <AboutList sections={[aboutSection]} />}
      </PaddedContentArea>
    </ScrollView>
  )
}

function userStatsToAboutSection(stats: any): AboutSection {
  const userStats: UserStats = stats.user
  return {
    heading: 'Users',
    members: [
      {
        heading: 'Users',
        members: [{ name: `${userStats.users}` }],
      },
      {
        heading: 'Dreams',
        members: [{ name: `${userStats.dreams}` }],
      },
      {
        heading: 'Users with Dreams',
        members: [{ name: `${userStats.usersWithDreams}` }],
      },
    ],
  }
}
