import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../../../api/_openapi'
import { FastDream } from '../../../../api/_openapi'
import { RootState } from '../root-reducer'
import { createObjectMap } from './modules/normalize'
import { PURGE } from 'redux-persist'
import {
  sortDreamsByDate,
  sortDreamsByShareDate,
} from './modules/dream-helpers'
import { createSelector } from 'reselect'

type FastDreamWithGroup = FastDream & { groupId: string }

// Dreams and tags are stored normalised in state
// But combined when they're queried for
export type DataState = {
  loading: boolean
  user?: User
  fastDreams: { [key: string]: FastDream }
  groupDreams: { [key: string]: FastDreamWithGroup }
  groupDreamsLoading: boolean
  lastSyncTime: string | null
}

const initialState: DataState = {
  loading: false,
  user: undefined,
  fastDreams: {},
  groupDreams: {},
  groupDreamsLoading: false,
  lastSyncTime: null,
}

const dreamSlice = createSlice({
  name: 'dream',
  initialState,
  // https://redux-toolkit.js.org/usage/usage-guide
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState
    })
  },
  reducers: {
    resetDreamTagState() {
      return initialState
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setGroupDreamsLoading(state, action: PayloadAction<boolean>) {
      state.groupDreamsLoading = action.payload
    },
    setFastDreams(state, action: PayloadAction<FastDream[]>) {
      state.fastDreams = createObjectMap(action.payload)
    },
    setGroupDreams(state, action: PayloadAction<FastDreamWithGroup[]>) {
      state.groupDreams = createObjectMap(action.payload)
    },
    addFastDream(state, action: PayloadAction<FastDream>) {
      state.fastDreams[action.payload.id] = action.payload
    },
    removeFastDream(state, action: PayloadAction<string>) {
      delete state.fastDreams[action.payload]
    },
    updateDreamsFromSync(state, action: PayloadAction<FastDream[]>) {
      const dreamMap = createObjectMap(action.payload)

      //remove deleted dreams
      const allDreams = { ...state.fastDreams, ...dreamMap }
      Object.keys(allDreams).forEach((key) => {
        if (allDreams[key].deleted) {
          delete allDreams[key]
        }
      })
      state.fastDreams = allDreams
    },
    unshareDream(state, action: PayloadAction<string>) {
      delete state.groupDreams[action.payload]
    },
    incReactionCount(
      state,
      action: PayloadAction<{ dreamId: string; shareId: string }>,
    ) {
      const { dreamId, shareId } = action.payload
      const groupDream = state.groupDreams[dreamId]
      if (groupDream && groupDream.shareId === shareId) {
        groupDream.reactionCount++
        groupDream.userReactionCount++
      }

      const userDream = state.fastDreams[dreamId]
      if (userDream && userDream.shareId === shareId) {
        userDream.reactionCount++
        userDream.userReactionCount++
      }
    },
    decReactionCount(
      state,
      action: PayloadAction<{ dreamId: string; shareId: string }>,
    ) {
      const { dreamId, shareId } = action.payload
      const groupDream = state.groupDreams[dreamId]
      if (groupDream && groupDream.shareId === shareId) {
        groupDream.reactionCount--
        groupDream.userReactionCount--
      }

      const userDream = state.fastDreams[dreamId]
      if (userDream && userDream.shareId === shareId) {
        userDream.reactionCount--
        userDream.userReactionCount--
      }
    },
    incCommentCount(state, action: PayloadAction<string>) {
      const shareId = action.payload
      const groupDream = Object.values(state.groupDreams).find(
        (dream) => dream.shareId === shareId,
      )
      if (groupDream) {
        groupDream.commentCount++
      }
    },
    setCommentCount(
      state,
      action: PayloadAction<{ shareId: string; count: number }>,
    ) {
      const { shareId, count } = action.payload
      const groupDream = Object.values(state.groupDreams).find(
        (dream) => dream.shareId === shareId,
      )
      if (groupDream) {
        groupDream.commentCount = count
      }
    },
    decCommentCount(state, action: PayloadAction<string>) {
      const shareId = action.payload
      const groupDream = Object.values(state.groupDreams).find(
        (dream) => dream.shareId === shareId,
      )
      if (groupDream) {
        groupDream.commentCount--
      }
    },
    setLastSyncTime(state, action: PayloadAction<string | null>) {
      state.lastSyncTime = action.payload
    },
  },
})

export const {
  setLoading,
  setFastDreams,
  setGroupDreams,
  setGroupDreamsLoading,
  addFastDream,
  removeFastDream,
  resetDreamTagState,
  updateDreamsFromSync,
  unshareDream,
  incReactionCount,
  decReactionCount,
  incCommentCount,
  decCommentCount,
  setCommentCount,
  setLastSyncTime,
} = dreamSlice.actions

// SELECTORS
export const selectDreamsAreLoading = (state: RootState): boolean => {
  return state?.dream.loading
}

// Get the last updatedAt date from the dreams
export const selectLastUpdatedDreamDate = (state: RootState): string => {
  const dreams = state?.dream.fastDreams
  if (!dreams) {
    return ''
  }
  const undeletedDreamIds: string[] = Object.values(dreams)
    .filter((d: FastDream) => !d.deleted)
    .map((d: FastDream) => d?.id as string)
    .filter((d?: string) => Boolean(d))
  return undeletedDreamIds.reduce((acc, id) => {
    const dream = dreams[id]
    const updatedAt = dream.updatedAt || dream.createdAt
    if (updatedAt > acc) {
      return updatedAt
    }
    return acc
  }, '')
}

const getFastDreams = (state: RootState) => state.dream.fastDreams || {}

export const selectNumDreamsInYear =
  (year: number) =>
  (state: RootState): number => {
    const storeHasDreams = state?.dream.fastDreams
    if (!storeHasDreams) {
      return 0
    } else {
      const dreams = Object.values(state.dream.fastDreams)
      const dreamsInYear = dreams.filter(
        (d) => new Date(d.date).getFullYear() === year,
      )
      return dreamsInYear.length
    }
  }

export const selectAllFastDreams = createSelector(
  [getFastDreams],
  (fastDreams) => {
    return sortDreamsByDate(Object.values(fastDreams))
  },
)

export const selectFastDreams = createSelector(
  [selectAllFastDreams],
  (fastDreams) => {
    return fastDreams.filter((d) => !d.isDraft)
  },
)

export const selectFastDraftDreams = createSelector(
  [selectAllFastDreams],
  (fastDreams) => {
    return fastDreams.filter((d) => d.isDraft)
  },
)

export const selectDiaryEntries = createSelector(
  [selectAllFastDreams],
  (fastDreams) => {
    return fastDreams.filter(
      (d) => d.type === 'journal-entry' && d.isDraft === false,
    )
  },
)

export const selectFirstDreamDate = createSelector(
  [selectFastDreams],
  (dreams) => {
    return dreams.length > 0
      ? dreams[dreams.length - 1].date
      : new Date().toISOString()
  },
)

const getGroupDreams = (state: RootState) => state.dream.groupDreams || {}

export const selectAllGroupDreams = createSelector(
  [getGroupDreams],
  (groupDreams) => {
    return sortDreamsByShareDate(Object.values(groupDreams))
  },
)

export const selectGroupDreamsLoading = (state: RootState): boolean => {
  return state?.dream.groupDreamsLoading
}

export const selectGroupDreamById =
  (id: string) =>
  (state: RootState): FastDream | undefined => {
    const groupDreams = state?.dream?.groupDreams
    if (!groupDreams) {
      console.log('No group dreams found. This should not happen.')
      return undefined
    } else {
      return groupDreams[id]
    }
  }

export const selectFastDreamById =
  (id: string) =>
  (state: RootState): FastDream | undefined => {
    return state?.dream.fastDreams[id]
  }

export const selectEarliestDreamDate = createSelector(
  [selectFastDreams],
  (dreams) => {
    if (dreams.length === 0) {
      return undefined
    } else {
      return new Date(dreams[dreams.length - 1].date)
    }
  },
)

export const storeHasDreams = createSelector(
  [selectFastDreams],
  (fastDreams) => {
    return fastDreams.length > 0
  },
)

export const selectLastSyncTime = (state: RootState): string | null => {
  return state?.dream.lastSyncTime
}

export default dreamSlice
