import React from 'react'
import i18n from '../../../i18n/i18nnext'
import { StyleSheet, Linking } from 'react-native'
import { AboutSection } from '../../../../../api/frontend-types'
import { HeadingSmall } from '../../common/copy/heading-small'
import { SansText } from '../../common/copy/text-sans'
import { Row } from '../../common/row/row'
import { getSansFont } from '../../../modules/language-helpers/language-helpers'
import { useTranslation } from 'react-i18next'
import { Box } from '../../common/box/box'
import { Text } from '../../common/copy/text'

type AboutListProps = {
  sections: AboutSection[]
}

export const AboutList = ({ sections }: AboutListProps) => {
  const userLanguage = i18n.resolvedLanguage || 'en'
  const { t } = useTranslation()

  return (
    <Box style={{ width: '100%' }}>
      {sections.map((section, i) => {
        const sectionHeadingLabel = section.headingKey
          ? t(section.headingKey)
          : section.heading
        return (
          <Box key={i} padding={2} w="100%">
            <HeadingSmall
              style={styles.heading}
              scriptType={section.scriptType}
            >
              {sectionHeadingLabel}
            </HeadingSmall>
            {section.members.map((section, i) => {
              const { heading, headingKey, scriptType, members } = section
              const headingText = headingKey ? t(headingKey) : heading
              return (
                <Row key={`${section.heading}-${i}`} mb={3}>
                  <SansText
                    style={styles.tableLeft}
                    fontSize={'md'}
                    scriptType={scriptType}
                    fontWeight="700"
                  >
                    {headingText}
                  </SansText>
                  <SansText style={styles.tableRight}>
                    {members.map((member, i) => {
                      const fontFamily = getSansFont(
                        userLanguage,
                        member.scriptType,
                      )
                      const { name, nameKey } = member
                      const label = nameKey ? t(nameKey) : name
                      return (
                        <Text key={`${label}-${i}`} fontFamily={fontFamily}>
                          <Text
                            style={{
                              textDecorationLine: member.link
                                ? 'underline'
                                : 'none',
                            }}
                            onPress={
                              member.link
                                ? () => Linking.openURL(member.link || '')
                                : undefined
                            }
                          >
                            {label || null}
                          </Text>
                          {i < section.members.length - 1 && ', '}
                        </Text>
                      )
                    })}
                  </SansText>
                </Row>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

const styles = StyleSheet.create({
  heading: {
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 30,
  },
  tableLeft: {
    width: '35%',
  },
  link: {
    textDecorationLine: 'underline',
  },
  tableRight: {
    width: '65%',
    marginHorizontal: 10,
  },
})
