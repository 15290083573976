import {
  ArtStyleService,
  FastTagService,
  InterpretationOptionsService,
  InterpretationStyleService,
  OpenAPI,
} from '../../../../../api/_openapi'
import { truncateString } from '../../../modules/strings/string-helpers'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { AppThunk } from '../../store'
import {
  setGenders,
  setLanguages,
  setDreamTypes,
  setThemes,
  setArtStyles,
  setInterpretationStyles,
  setFamousUsers,
  setFeatureMap,
  setInterpretationOptions,
  setSymbolTags,
} from '../ui'
import { fetchFamousDreamers } from '../../user/functions/user-functions'
import axios from 'axios'

export function loadGenders(): AppThunk {
  return async (dispatch: any) => {
    try {
      const genders = await axios({
        method: 'get',
        url: `${OpenAPI.BASE}/api/genders`,
        headers: {
          Accept: 'application/json',
        },
      }).then(({ data }) => {
        return data
      })
      if (genders && genders.length > 0) {
        // console.log('Setting genders: ', genders)
        dispatch(setGenders(genders))
      } else {
        console.log('No genders returned, setting to empty array')
        dispatch(setGenders([]))
      }
    } catch (err: any) {
      elsewhereToast.showToast({
        title: 'toast.loadingGendersError.title',
        description: truncateString(err.message, 100),
        status: 'error',
      })
    }
  }
}

export function loadSystemTags(): AppThunk {
  return async (dispatch: any) => {
    FastTagService.typeTags([['DREAM_TYPE', 'SYMBOL', 'THEME'].join(',')]).then(
      (systemTags) => {
        const typeTags = systemTags.filter((tag) => tag.type === 'DREAM_TYPE')
        const symbolTags = systemTags.filter((tag) => tag.type === 'SYMBOL')
        const themeTags = systemTags.filter((tag) => tag.type === 'THEME')
        dispatch(setDreamTypes(typeTags))
        dispatch(setThemes(themeTags))
        dispatch(setSymbolTags(symbolTags))
      },
    )
  }
}

export function loadArtStyles(): AppThunk {
  return async (dispatch: any) => {
    const artStyles = await ArtStyleService.apiArtStylesGetCollection().catch(
      (err) => {
        console.log('Error getting art styles: ', err)
        elsewhereToast.showToast({
          title: 'toast.loadingArtStylesError.title',
          description: truncateString(err.message, 100),
          status: 'error',
        })
      },
    )
    if (artStyles) {
      dispatch(setArtStyles(artStyles))
    } else {
      dispatch(setArtStyles([]))
    }
  }
}

export function loadInterpretationStyles(): AppThunk {
  return async (dispatch: any) => {
    const interpretationStyles =
      await InterpretationStyleService.apiInterpretationStylesGetCollection().catch(
        (err) => {
          console.log('Error getting interpretation styleszz: ', err)
          elsewhereToast.showToast({
            title: 'toast.loadingInterpretationStylesError.title',
            description: truncateString(err.message, 100),
            status: 'error',
          })
        },
      )

    if (interpretationStyles) {
      dispatch(setInterpretationStyles(interpretationStyles))

      await InterpretationOptionsService.interpretationOptions()
        .then((options) => {
          dispatch(setInterpretationOptions(options))
        })
        .catch((err) => {
          console.log('Error getting interpretation options: ', err)
          elsewhereToast.showToast({
            title: 'common.errorGeneric',
            description: truncateString(err.message, 100),
            status: 'error',
          })
        })
    } else {
      dispatch(setInterpretationStyles([]))
    }
  }
}

export function loadFeatureMap(token: string): AppThunk {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${OpenAPI.BASE}/api/feature-map`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      // console.log('Got feature map: ', response.data)
      dispatch(setFeatureMap(response.data))
    } catch (err) {
      console.log('Error getting feature map: ', err)
    }
  }
}

export function loadLanguages(): AppThunk {
  return async (dispatch: any) => {
    try {
      const languages = await axios({
        method: 'get',
        url: `${OpenAPI.BASE}/api/languages`,
        headers: {
          Accept: 'application/json',
        },
      }).then(({ data }) => {
        return data
      })
      if (languages) {
        dispatch(setLanguages(languages))
      } else {
        dispatch(
          setLanguages([
            {
              code: 'en',
              name: 'English',
              supported: true,
              createdAt: new Date().toISOString(),
            },
          ]),
        )
      }
    } catch (err: any) {
      elsewhereToast.showToast({
        title: 'toast.loadingLanguagesError.title',
        description: truncateString(err.message, 100),
        status: 'error',
      })
    }
  }
}

export function loadFamousUsers(): AppThunk {
  return async (dispatch: any) => {
    try {
      const famousUsers = await fetchFamousDreamers().catch((err) => {
        elsewhereToast.showToast({
          title: 'Error fetching famous dreamers',
          description: truncateString(err.message, 100),
          status: 'error',
        })
      })

      if (famousUsers) {
        dispatch(setFamousUsers(famousUsers))
      }
    } catch (err) {
      console.log('Error getting famous users: ', err)
    }
  }
}
