import React, { useEffect, useState } from 'react'
import { ScrollView } from '../../components/common/scrollview/scrollview'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import YearlyReportText from './yearly-report-text'
import { EndOfYearWrapped } from '../../../../api/_openapi'
import { LoadingPage } from '../../components/layout/loading-page'
import { useDispatch } from 'react-redux'
import { setHasViewedYearlyReport } from '../../ducks/ui/ui'
import {
  generateYearlyReport,
  getYearlyReport,
} from '../../ducks/reports/functions/report-functions'

export default function ReportYearly() {
  // STATE
  const [reportData, setReportData] = useState<EndOfYearWrapped | null>(null)

  // HOOKS
  const dispatch = useDispatch<any>()

  useEffect(() => {
    // Try to get the yearly report if it's already generated
    getYearlyReport().then((res) => {
      // TODO: Handle different years
      if (res && res[0]) {
        setReportData(res[0])
      } else {
        // Generate the yearly report if it's not found
        generateYearlyReport().then((res) => {
          if (res && typeof res === 'object' && res.year) {
            setReportData(res)
          } else {
            setReportData(null)
          }
        })
      }
    })
  }, [])

  // Set hasViewedYearlyReport to true
  // (removes the badge from the dashboard menu item)
  useEffect(() => {
    dispatch(setHasViewedYearlyReport(true))
  }, [])

  if (!reportData) {
    return <LoadingPage />
  }

  return (
    <ScrollView
      contentContainerStyle={{
        width: '100%',
        maxWidth: WEB_MAX_WIDTH,
        marginHorizontal: 'auto',
      }}
    >
      <YearlyReportText report={reportData} />
    </ScrollView>
  )
}
