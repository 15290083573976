import { elsewhereToast } from '../../../components/common/toast/toast'
import { DREAM_MAPPING_COLLECTION } from '../../../constants/constants'
import { FastDreamService } from '../../../../../api/_openapi'

export async function addDreamToPublicMap(dreamId: string): Promise<void> {
  try {
    await FastDreamService.addDreamToPublicCollection(
      DREAM_MAPPING_COLLECTION,
      'the documentation requires this...wtf',
      {
        id: dreamId,
      },
    )
    elsewhereToast.showToast({
      title: 'Success',
      description: 'toast.dreamsShared.description',
      status: 'success',
    })
  } catch (e: any) {
    elsewhereToast.showToast({
      title: 'common.error',
      description: e.message,
      status: 'error',
    })
  }
}

export async function isDreamOnPublicMap(dreamId: string): Promise<boolean> {
  try {
    const dream = await FastDreamService.getDreamInPublicCollection(
      DREAM_MAPPING_COLLECTION,
      dreamId,
    )
    console.log('isDreamOnMap', dream)
    return dream !== null
  } catch (e) {
    //404 means the dream is not on the map
    console.log('Error checking if dream is on map', e)
    return false
  }
}

export async function removeDreamFromPublicMap(dreamId: string) {
  try {
    await FastDreamService.removeDreamFromPublicCollection(
      DREAM_MAPPING_COLLECTION,
      dreamId,
    )
    elsewhereToast.showToast({
      title: 'Success',
      description: 'toast.dreamsUnshared.description',
      status: 'success',
    })
  } catch (e: any) {
    console.log('Error removing dream from map')
    elsewhereToast.showToast({
      title: 'common.error',
      description: e.message,
      status: 'error',
    })
  }
}
