import React, { FC, ReactNode } from 'react'
import i18n from 'i18next'
import { Size } from '../../../core/theme'
import { Platform } from 'react-native'
import { TextProps, Text } from './text'
import {
  getSansFont,
  getScriptTypeForLanguage,
  iosLineHeightForFontsizeArabic,
} from '../../../modules/language-helpers/language-helpers'
import {
  ScriptType,
  SupportedLanguage,
} from '../../../../../api/frontend-types'
import {
  MD_LINE_HEIGHT,
  MEDIUM_FONT_SIZE,
  SANS_BOOK_FONT_WEIGHT,
} from '../../../constants/constants'

type SansTextProps = TextProps & {
  children?: ReactNode
  language?: SupportedLanguage
  scriptType?: ScriptType
  fontSize?: number | Size
}

export const SansText: FC<SansTextProps> = ({
  children,
  fontWeight = SANS_BOOK_FONT_WEIGHT,
  fontSize = MEDIUM_FONT_SIZE,
  lineHeight = MD_LINE_HEIGHT,
  textTransform = 'none',
  language,
  scriptType,
  ...rest
}: SansTextProps) => {
  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const textAlign = i18n.dir() === 'rtl' ? 'right' : 'left'
  const font = getSansFont(lang, scriptType)
  const isIos = Platform.OS === 'ios'
  const typeOfScript = scriptType || getScriptTypeForLanguage(lang)
  const isArabicScript = typeOfScript === 'arabic'
  const isArabicFontOnIos = isArabicScript && isIos

  // The combination of fontsize and font that works best for Arabic on iOS
  // is different from the other languages, for some reason
  const lh = isArabicFontOnIos
    ? iosLineHeightForFontsizeArabic(fontSize)
    : lineHeight

  return (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={font}
      selectable={true}
      lineHeight={lh}
      textTransform={textTransform}
      textAlign={textAlign}
      {...rest}
    >
      {children}
    </Text>
  )
}
