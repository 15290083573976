import React, { MutableRefObject, RefCallback } from 'react'
import { IInputProps, Input, useColorModeValue } from 'native-base'
import { Platform } from 'react-native'
import { BLACK, WHITE } from '../../../constants/ui-constants'
import i18n from '../../../i18n/i18nnext'
import { getFontSuffix } from '../../../modules/language-helpers/language-helpers'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { XXL_LINE_HEIGHT } from '../../../constants/constants'

type InputHeadingProps = IInputProps & {
  // Make placeholder required
  placeholder: string
  inputRef?: MutableRefObject<any> | RefCallback<any>
}

export const InputHeading = (props: InputHeadingProps) => {
  const { value, onChangeText, inputRef, ...otherProps } = props
  const borderColor = useColorModeValue(BLACK, WHITE)

  // VARS
  const language = (i18n.resolvedLanguage || 'en') as SupportedLanguage
  const fontSuffix = getFontSuffix(language)
  const fontKey = `heading${fontSuffix}`

  return (
    <Input
      ref={inputRef}
      {...otherProps}
      _focus={{ borderColor }}
      fontSize={'2xl'}
      size={'2xl'}
      fontFamily={fontKey}
      fontWeight={'300'}
      variant={'unstyled'}
      style={{
        textAlignVertical: 'center',
        textTransform: Platform.OS === 'web' ? 'uppercase' : undefined,
        lineHeight: getLineHeightForLanguage(language), // Somehow this is needed to make the text vertically centered
      }}
      value={value}
      onChangeText={onChangeText}
      autoCapitalize={'characters'}
      textAlign={'center'}
      width={'100%'}
      multiline={true}
      p={0}
    />
  )
}

function getLineHeightForLanguage(language: SupportedLanguage) {
  switch (language) {
    case 'fa':
      return 80
    case 'ar':
      return 80
    default:
      return XXL_LINE_HEIGHT
  }
}
