import { deleteDream } from '../../../../ducks/dream-tag/thunks/dream-thunks'
import { AlertPopup } from '../../../common/dialog/alert-dialog'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/core'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../../ducks/root-reducer'
import { selectPrivateGroupId } from '../../../../ducks/groups/groups'
import { FastDream } from '../../../../../../api/_openapi'

export const DeleteDreamAlert = ({
  isOpen,
  onClose,
  dream,
}: {
  isOpen: boolean
  onClose: () => void
  dream: FastDream
}) => {
  const { t } = useTranslation()
  const navigation = useNavigation<any>()
  const dispatch = useDispatch<any>()
  const privateGroupId = useSelector(selectPrivateGroupId)

  const confirmMessage = dream.title
    ? t('deleteDreamDialog.description.hasTitle', { titleParam: dream.title })
    : t('deleteDreamDialog.description.noTitle')

  return (
    <AlertPopup
      isOpen={isOpen}
      header={t('deleteDreamDialog.title')}
      description={confirmMessage}
      confirmLabel={t('common.delete')}
      onConfirm={() => {
        onClose()
        dispatch(deleteDream(privateGroupId || '', dream.id))
        navigation.replace('MainTabs', {
          screen: 'MainStack',
          params: {
            screen: 'PrivateFeed',
          },
        })
      }}
      onClose={onClose}
    />
  )
}
