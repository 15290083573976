import React from 'react'
import { useTranslation } from 'react-i18next'
import { SansText } from '../../common/copy/text-sans'
import { InterfaceTextProps } from 'native-base/lib/typescript/components/primitives/Text/types'
import Autolink from 'react-native-autolink'
import { useLinkProps } from '../../../utilities/useLinkPropsFixed'
import { Center } from '../../common/center/center'
import { Size } from '../../../core/theme'

type TranslationParams = [string] | [string, { count: number }]
type CountedSubscriptionBenefit =
  | 'imagesRemaining'
  | 'insightsRemaining'
  | 'reportsRemaining'
  | 'interpretationsRemaining'

export type SubscriptionStatusTextProps = InterfaceTextProps & {
  statusToShow: CountedSubscriptionBenefit
  itemsRemaining: number | null | undefined // Number of images or insights etc
  creditsRemaining: number | null | undefined // Number of extra credits the user has
  tier: string | undefined
  color: string
  fontSize?: number | Size
}

export const SubscriptionStatusText = ({
  statusToShow,
  itemsRemaining,
  creditsRemaining,
  tier,
  color,
  fontSize = 'md',
  ...rest
}: SubscriptionStatusTextProps) => {
  // HOOKS
  const { t } = useTranslation()

  // VARS
  const isForReports = statusToShow === 'reportsRemaining'
  const shouldShowBuyOptions = !itemsRemaining && !creditsRemaining // Show buy options if no items or credits
  const shouldShowCreditsRemaining =
    !isForReports && !itemsRemaining && creditsRemaining // If the user has credits but no items, tell them they will be using credits
  const shouldShowUpgradeOption =
    !isForReports && shouldShowBuyOptions && tier !== 'supporter' // Supporters can't upgrade to anything

  // TRANSLATION
  // 1) Number of remaining items
  const translationParams = makeTranslationParams(statusToShow, itemsRemaining)
  const remainingItemsText = t(...translationParams)
  // 2) Use or buy credits or upgrade text

  // Show the appropriate text

  // Text options
  // a) Use credits instead to generate the thing
  const useCreditsInsteadKey = `subscriptions.status.${statusToShow}.creditAlternative`
  // b) Buy credits or upgrade
  const buyCreditsKey =
    statusToShow !== 'reportsRemaining'
      ? `subscriptions.status.${statusToShow}.howToGetMore.credits`
      : ''
  const { onPress: creditsOnPress, ...restCredits } = useLinkProps({
    to: { screen: 'PrivateCreditSettingsEdit' },
  })
  const buyCreditsText = (
    <Autolink
      text={t(buyCreditsKey)}
      matchers={[
        {
          pattern: /<linkTag>(.*)<\/linkTag>/g,
          getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
          onPress: () => creditsOnPress(),
        },
      ]}
      linkProps={{
        style: {
          textDecorationLine: 'underline',
          color,
        },
        onPress: creditsOnPress,
        ...restCredits,
      }}
    />
  )
  const orUpgradeKey = `subscriptions.status.${statusToShow}.howToGetMore.upgrade`
  const { onPress: upgradeOnPress, ...restUpgrade } = useLinkProps({
    to: { screen: 'PrivateSubscriptionSettingsEdit' },
  })
  const orUpgradeText = (
    <Autolink
      text={t(orUpgradeKey)}
      matchers={[
        {
          pattern: /<linkTag>(.*)<\/linkTag>/g,
          getLinkText: (replacerArgs) => `${replacerArgs[1]}`,
          onPress: () => upgradeOnPress(),
        },
      ]}
      linkProps={{
        style: {
          textDecorationLine: 'underline',
          color,
        },
        onPress: upgradeOnPress,
        ...restUpgrade,
      }}
    />
  )

  // (Show nothing if there are items remaining)
  let creditsText: string | JSX.Element = ''
  if (shouldShowCreditsRemaining) {
    creditsText = t(useCreditsInsteadKey, { count: creditsRemaining })
  } else if (shouldShowBuyOptions) {
    creditsText = buyCreditsText
  }

  return (
    <Center {...rest}>
      <SansText color={color} fontSize={fontSize} textAlign="center">
        {remainingItemsText} {creditsText}{' '}
        {shouldShowUpgradeOption && orUpgradeText}
      </SansText>
    </Center>
  )
}

function makeTranslationParams(
  status: CountedSubscriptionBenefit,
  numRemaining: number | null | undefined,
): TranslationParams {
  const someRemaining = Boolean(numRemaining)
  const key = makeTranslationKey(status, numRemaining)
  if (someRemaining) {
    return [key, { count: numRemaining || 0 }]
  } else {
    return [key]
  }
}

function makeTranslationKey(
  status: CountedSubscriptionBenefit,
  numRemaining: number | null | undefined,
) {
  const someRemaining = Boolean(numRemaining)
  const additionForNone = someRemaining ? '' : '.none'
  return `subscriptions.status.${status}${additionForNone}`
}
