import { camelCase } from '../strings/string-helpers'

// Specific color, general color, hex
type ColorParts = [string, string, string]

const translatableColors = [
  'red',
  'orange',
  'brown',
  'yellow',
  'green',
  'turquoise',
  'blue',
  'purple',
  'pink',
  'black',
  'white',
  'cream',
  'gray',
  'gold',
  'silver',
  'bronze',
  'dark red-brown',
  'golden yellow',
  'light golden brown',
  'light yellow-brown',
  'yellow-green',
  'dark olive green',
  'light spring green',
  'turquoise',
  'teal',
  'prussian blue',
  'dark royal blue',
  'light purple',
  'magenta',
]

const translatableColorKeys = translatableColors.map((color) =>
  camelCase(color),
)

// Take a colour string like "light brown (blue) #2797e7"
// And extract the three parts (specific color, general color, hex)
export function extractColorParts(color: string): ColorParts {
  // Get the hex part with regex
  const hex = color.match(/#[0-9a-fA-F]{6}/)
  // Get the general color part (in brackets)
  const general = color.match(/\(([^)]+)\)/)
  // Get the specific color part (before the brackets)
  const specific = color.split('(')[0]
  if (!hex || !general || !specific) {
    console.log('Invalid color string:', color)
    return ['', '', '#000000']
  }
  const specificColor = camelCase(specific)
  const generalColor = general[1].trim()
  const hexColor = hex[0]
  return [specificColor, generalColor, hexColor]
}

// Take a color and return the closest translatable color
// Usually the general color (middle part of the color string) is the translatable part
// But sometimes the specific color is more appropriate (e.g. turquoise)
export function getTranslatableColor(parts: ColorParts): string {
  const specific = parts[0]
  const general = parts[1]
  if (translatableColorKeys.includes(specific)) {
    return specific
  } else {
    return general
  }
}

export function i18nKeyFromColorStr(color: string): string {
  const parts = extractColorParts(color)
  const translatableColor = getTranslatableColor(parts)
  const key = `colors.${translatableColor}`
  return key
}

export function hexFromColorString(color: string | undefined): string {
  if (!color) {
    console.log('No color provided')
    return '#000000'
  }
  const parts = extractColorParts(color)
  const hexPart = parts[2]
  // Make sure it's a valid hex color
  if (!hexPart.match(/#[0-9a-fA-F]{6}/)) {
    console.log('Invalid hex color:', hexPart)
    return '#000000'
  }
  return hexPart
}
