import { SupportedLanguage } from '../../../../api/frontend-types'

const WORDS_TO_NOT_CAPITALIZE: { [key in SupportedLanguage]: string[] } = {
  en: [
    'to',
    'the',
    'a',
    'an',
    'and',
    'but',
    'or',
    'nor',
    'at',
    'by',
    'for',
    'from',
    'in',
    'into',
    'near',
    'of',
    'on',
    'onto',
    'to',
    'with',
    'as',
    'for',
    'in',
    'of',
    'per',
    'to',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'as',
    'at',
    'by',
    'for',
    'in',
    'of',
    'on',
    'per',
    'to',
    'up',
    'via',
    'with',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'as',
    'at',
    'by',
    'for',
    'in',
    'of',
    'on',
    'per',
    'to',
    'up',
    'via',
    'with',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'as',
    'at',
    'by',
    'for',
    'in',
    'of',
    'on',
    'per',
    'to',
    'up',
    'via',
    'with',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'as',
    'at',
    'by',
    'for',
    'in',
    'of',
    'on',
    'per',
    'to',
    'up',
    'via',
    'with',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'as',
    'at',
    'by',
    'for',
    'in',
    'of',
    'on',
    'per',
    'to',
    'up',
    'via',
    'with',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'a',
    'an',
    'the',
    'as',
    'at',
    'by',
    'for',
    'in',
    'of',
    'on',
    'per',
    'to',
    'up',
    'via',
    'with',
    'and',
    'but',
    'or',
    'nor',
    'for',
    'so',
    'yet',
    'a',
    'an',
    'the',
  ],
  ja: [],
  de: [
    'der',
    'die',
    'das',
    'dem',
    'den',
    'des',
    'ein',
    'eine',
    'einem',
    'einen',
    'eines',
  ],
  fr: ['de', 'du', 'le', 'la', 'les', 'un', 'une', 'des', 'au', 'aux'],
  id: [
    'di',
    'ke',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
    'di',
    'dari',
    'dan',
    'atau',
    'tetapi',
    'ataupun',
    'pada',
    'dengan',
    'sebagai',
    'untuk',
    'dari',
    'dalam',
    'ke',
  ],
  es: [],
  pt: [],
  da: [],
  uk: [],
  hu: [],
  pl: [],
  ru: [],
  fa: [],
  el: [],
  it: [],
  cs: [],
  ar: [],
  he: [],
}

export function titleCase(str: string, language: SupportedLanguage): string {
  const words = str.split(' ')
  const capitalizedWords = words.map((word) => {
    const lowerCaseWord = word.toLowerCase()
    if (WORDS_TO_NOT_CAPITALIZE[language].includes(lowerCaseWord)) {
      return lowerCaseWord
    }
    return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1)
  })
  return capitalizedWords.join(' ')
}

// Naive title case
export function title(str: string): string {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
