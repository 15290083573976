import React, { useMemo } from 'react'
import { TagType } from '../../../../../api/frontend-types'
import { TagPill } from './tag-pill'
import { useSelector } from '../../../ducks/root-reducer'
import { separateTagsForDisplay } from '../../../modules/tag-helpers/tag-helpers'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { selectPrivateGroupId } from '../../../ducks/groups/groups'
import { idEquals } from '../../../ducks/helpers'
import { useRoute } from '@react-navigation/core'
import { MainStackNavigationProp } from '../../../navigation/types'
import { FastDream, FastTag } from '../../../../../api/_openapi'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { View } from '../../common/view/view'
import { Row } from '../../common/row/row'

export const TAGTYPE_ORDER: TagType[] = [
  'CHARACTER',
  'ANIMAL',
  'SETTING',
  'EMOTION',
  'ACTION',
  'OTHER',
  'THEME',
  'DREAM_TYPE',
  'COLOR',
]

type TagAreaProps = {
  tags: FastTag[]
  dream: FastDream
}

export const TagArea = React.memo(({ tags }: TagAreaProps) => {
  // Vars
  const navigation =
    useNavigation<MainStackNavigationProp<'PrivateDreamView'>>()
  // Selectors
  const route = useRoute<any>()
  const privateGroupId = useSelector(selectPrivateGroupId)
  const activeGroupId = useActiveGroupId()

  // Hooks
  const { t } = useTranslation()

  // Memoized values
  const isUserPrivateGroup = useMemo(() => {
    return idEquals(activeGroupId, privateGroupId)
  }, [activeGroupId, privateGroupId])

  const isLibraryDreamView = useMemo(() => {
    return route?.name === 'LibraryDreamView'
  }, [route])

  const { regularTags, colorTags, dreamTypeTags, themeTags } = useMemo(() => {
    return separateTagsForDisplay(tags)
  }, [tags])

  const orderedTags = useMemo(() => {
    const tagsToOrder = [
      ...regularTags,
      ...colorTags,
      ...dreamTypeTags,
      ...themeTags,
    ]
    // Sort tags by type
    return (
      tagsToOrder
        .filter((tag) => {
          // If the tag is not in the TAGTYPE_ORDER array,
          // it will be filtered out. In case we add a new tag type on the backend,
          // it will be filtered out until we add it to the TAGTYPE_ORDER array
          return TAGTYPE_ORDER.includes(tag.type)
        })
        .sort((a, b) => {
          const aIndex = TAGTYPE_ORDER.indexOf(a.type)
          const bIndex = TAGTYPE_ORDER.indexOf(b.type)
          return aIndex - bIndex
        })
        // Add theme translations
        .map((tag) => {
          if (tag.type === 'THEME') {
            return {
              ...tag,
              name: t(`${tag.name}`),
            }
          }
          return tag
        })
    )
  }, [regularTags, colorTags])

  return (
    <View width={'100%'}>
      <Row
        style={{
          flexWrap: 'wrap',
        }}
        mb={3}
        px={undefined}
      >
        {orderedTags.map((tag, i) => {
          return (
            <TagPill
              tag={tag}
              tagState={'passive'}
              key={`tag-pill-${tag.id}`}
              //todo: fast Tag pages for groups not implemented'
              onPassiveTagPress={
                isUserPrivateGroup && !isLibraryDreamView
                  ? () => {
                      navigation.push('PrivateTagView', {
                        id: tag.id,
                      })
                    }
                  : undefined
              }
            />
          )
        })}
      </Row>
    </View>
  )
})
