/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EndOfYearWrapped } from '../models/EndOfYearWrapped';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EndOfYearWrappedService {
  /**
   * Retrieves the collection of EndOfYearWrapped resources.
   * Retrieves the collection of EndOfYearWrapped resources.
   * @param page The collection page number
   * @param itemsPerPage The number of items per page
   * @returns EndOfYearWrapped EndOfYearWrapped collection
   * @throws ApiError
   */
  public static endOfYearWrapped(
    page: number = 1,
    itemsPerPage: number = 30,
  ): CancelablePromise<Array<EndOfYearWrapped>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/insights/end-of-year-wrapped',
      query: {
        'page': page,
        'itemsPerPage': itemsPerPage,
      },
    });
  }
  /**
   * Retrieves a EndOfYearWrapped resource.
   * Retrieves a EndOfYearWrapped resource.
   * @returns EndOfYearWrapped EndOfYearWrapped resource
   * @throws ApiError
   */
  public static generateEndOfYearWrapped(): CancelablePromise<EndOfYearWrapped> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/insights/end-of-year-wrapped/generate',
      errors: {
        404: `Resource not found`,
      },
    });
  }
}
