import * as React from 'react'
import { HighlightBox } from './highlight-box'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { Defer } from '../../utilities/Defer'
import { Loading } from './loading'
import { Row } from '../common/row/row'
import i18n from '../../i18n/i18nnext'
import {
  getNumGridColsPerRow,
  splitArrayIntoRows,
} from '../../modules/ui-helpers/grid-helpers'
import { Box, InterfaceBoxProps } from '../common/box/box'

// Need unique ids for adding/removing items
export type GridItem = {
  id: string
  item: JSX.Element
}

type LoadingItem = GridItem & {
  loading: boolean
}

type GridDisplayProps = InterfaceBoxProps & {
  width: number
  items: GridItem[]
  forceSquare?: boolean
  loading?: boolean
}

export const GridDisplay = ({
  width,
  items,
  forceSquare,
  loading,
  ...rest
}: GridDisplayProps) => {
  // Calculate the width of each box
  width = Math.min(width, WEB_MAX_WIDTH)
  const squaresPerRow = getNumGridColsPerRow(width)
  const boxWidth = width / squaresPerRow

  // Add a loading item to the end of the items array
  let allItems: LoadingItem[] = items.map((item) => ({
    ...item,
    loading: false,
  }))
  if (loading) {
    const loadingItem: LoadingItem = {
      item: <Loading />,
      loading: true,
      id: 'loading',
    }
    allItems = [...allItems, loadingItem]
  }

  const isRtl = i18n.dir() === 'rtl'

  // Split the symbolKeys into rows
  const itemRows = splitArrayIntoRows(allItems, squaresPerRow)

  // Single symbol row is centered, multiple rows are left-aligned
  const singleRow = itemRows.length === 1

  return (
    <Box width={'100%'} maxWidth={WEB_MAX_WIDTH} {...rest}>
      <Defer chunkSize={5}>
        {itemRows.map((itemRow, i) => {
          return (
            <Row
              key={i}
              width={'100%'}
              style={{
                justifyContent: singleRow ? 'center' : 'flex-start',
                maxWidth: WEB_MAX_WIDTH,
              }}
            >
              <Defer chunkSize={squaresPerRow}>
                {itemRow.map((loadingItem, j) => {
                  const { item, loading, id } = loadingItem
                  const inFirstRow = i === 0
                  const inFirstColumn = j === 0
                  const inLastColumn =
                    j === squaresPerRow - 1 || j === itemRow.length - 1
                  if (loading) {
                    return (
                      <Box
                        key={id}
                        width={boxWidth}
                        height={forceSquare ? boxWidth : undefined}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                        p={0}
                      >
                        {item}
                      </Box>
                    )
                  } else {
                    return (
                      // A single symbol box with a border
                      <HighlightBox
                        key={id}
                        width={boxWidth}
                        height={forceSquare ? boxWidth : undefined}
                        p={0}
                        hasBorderTop={inFirstRow}
                        hasBorderLeft={
                          (!isRtl && inFirstColumn) || (isRtl && inLastColumn)
                        }
                        hasBorderRight={true}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        {item}
                      </HighlightBox>
                    )
                  }
                })}
              </Defer>
            </Row>
          )
        })}
      </Defer>
    </Box>
  )
}
