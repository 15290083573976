import { FastDream } from '../../../../api/_openapi'

type DatePart = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds'

// Add years, months, days, hours, minutes, seconds to a date
export function addTimeToDate(
  date: Date,
  addition: { [key in DatePart]?: number },
) {
  const newDate = new Date(date)
  if (addition.years) {
    newDate.setFullYear(newDate.getFullYear() + addition.years)
  }
  if (addition.months) {
    newDate.setMonth(newDate.getMonth() + addition.months)
  }
  if (addition.days) {
    newDate.setDate(newDate.getDate() + addition.days)
  }
  if (addition.hours) {
    newDate.setHours(newDate.getHours() + addition.hours)
  }
  if (addition.minutes) {
    newDate.setMinutes(newDate.getMinutes() + addition.minutes)
  }
  if (addition.seconds) {
    newDate.setSeconds(newDate.getSeconds() + addition.seconds)
  }
  return newDate
}

// Get dreams in range
export const getDreamsInRange = (
  dreams: FastDream[],
  start: Date | null | undefined,
  end?: Date,
): FastDream[] => {
  // TODO: Doesn't deal with dates before 1000 AD
  const startDate = start || new Date('1000-01-01T00:00:00+00:00')
  const endDate = end || new Date()

  // Get all dreams in the date range
  // The "gotcha" here is that the date is stored without a timezone
  // So calling new Date(dream.date) will assume that the date is UTC
  // And then convert it to the local timezone,
  // which may create a date that is later than the user's actual date
  // E.g. if I enter a dream on Nov 7 at 9am in Sydney,
  // That's stored as "2023-11-07T00:00:00+00:00"
  // and new Date("2023-11-07T00:00:00+00:00") returns
  // Tue Nov 07 2023 11:00:00 GMT+1100 (Australian Eastern Daylight Time), i.e.
  // Nov 7 at 11am in Sydney
  // In which case dreamDate <= endDate will be false,
  // and the dream won't be included in my stats for "in the past week" etc.
  // So we need to add the offset to UTC to the date to get the local time
  const offset = new Date().getTimezoneOffset()
  return dreams.filter((dream) => {
    // This is in UTC
    const dreamDate = new Date(dream.date)
    // This is in local time
    const dreamDateWithOffset = new Date(
      dreamDate.getTime() + offset * 60 * 1000,
    )
    const inRange =
      dreamDateWithOffset >= startDate && dreamDateWithOffset <= endDate
    return inRange && !dream.deleted
  })
}

// Pass in array of string dates
// Return min and max dates as Date objects
export function getMinMaxDate(dates: string[]): { min: Date; max: Date } {
  const dateObjects = dates.map((d) => new Date(d))
  const min = new Date(Math.min(...dateObjects.map((d) => d.getTime())))
  const max = new Date(Math.max(...dateObjects.map((d) => d.getTime())))
  return { min, max }
}

export function todayThisHourTenMinsString(): string {
  return new Date().toISOString().substring(0, 15)
}

// Check if a date string is in the format "YYYY-MM-DD"
// And that it's a valid date
export function isValidYYYYMMDDDate(
  dateString: string | null | undefined,
): boolean {
  // If no date is provided, return false
  if (!dateString) {
    return false
  }
  const date = new Date(dateString)
  // If date is invalid, return false
  if (date.toString() === 'Invalid Date') {
    return false
  } else {
    return (
      date.toISOString().substring(0, 10) === dateString &&
      date.toString() !== 'Invalid Date'
    )
  }
}

export function getFactoidDateString(
  month: number | undefined,
  year: number,
  language: string,
): string {
  const date = new Date(year, (month || 1) - 1)
  return date.toLocaleString(language, { month: 'short', year: 'numeric' })
}
