import {
  EndOfYearWrapped,
  EndOfYearWrappedService,
} from '../../../../../api/_openapi'

// Generate
// elsewhere.to/api/insights/end-of-year-wrapped/generate
export async function generateYearlyReport(): Promise<EndOfYearWrapped> {
  const report = await EndOfYearWrappedService.generateEndOfYearWrapped().then(
    (data) => {
      return data
    },
  )

  return report
}

export async function getYearlyReport(): Promise<EndOfYearWrapped[]> {
  const report = await EndOfYearWrappedService.endOfYearWrapped().then(
    (data) => {
      return data
    },
  )
  return report
}
