import React, { useMemo } from 'react'
import { Dimensions } from 'react-native'
import { Loading } from '../../components/layout/loading'
import { NoContentMessage } from '../../components/layout/no-content-message'
import FeedItemInsight from '../../components/composite/feed/feed-item-insight'
import { getTitleForInsight } from '../../modules/analysis-helpers/insight-helpers'
import { WEB_MAX_WIDTH } from '../../constants/constants'
import { RouteProp } from '@react-navigation/core'
import { MainStackParamList } from '../../navigation/types'
import { Insight } from '../../../../api/_openapi'

type InsightTabProps = {
  route: RouteProp<MainStackParamList, 'PrivateAskYourDreams'>
  insights: Insight[]
  loading: boolean
  noInsightsMsg: string
  setInsights: any
}

export const AnswersTab = ({
  route,
  insights,
  loading,
  noInsightsMsg,
}: InsightTabProps) => {
  // First we title the insights
  const screenWidth = Math.min(Dimensions.get('window').width, WEB_MAX_WIDTH)
  const titledInsights: Insight[] = useMemo(() => {
    return insights.map((insight) => {
      const title = getTitleForInsight(insight, null, true, screenWidth)
      return {
        ...insight,
        title: title,
      }
    })
  }, [insights, screenWidth])

  if (loading) {
    return <Loading />
  } else if (titledInsights.length === 0) {
    return <NoContentMessage message={noInsightsMsg} />
  }

  return (
    <>
      {titledInsights.map((insight) => {
        return (
          <FeedItemInsight route={route} key={insight.id} insight={insight} />
        )
      })}
    </>
  )
}
