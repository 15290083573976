import React from 'react'
import { ContentDivider } from '../../../components/layout/content-divider'
import { HeadingSans } from '../../../components/common/copy/heading-sans'
import { useTranslation } from 'react-i18next'
import { LoadingPage } from '../../../components/layout/loading-page'
import { Tabs } from '../../../components/layout/tab-view-tabs'
import { BodyText } from '../../common/copy/text-body'
import DreamComments from '../../../screens/Dreams/DreamView/DreamComments'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { PaddedContentAreaConditional } from '../../layout/content-area-padded-conditional'
import { FormScrollWrapper } from '../../common/form/form-scroll-wrapper'
import { FastDream } from '../../../../../api/_openapi'
import { WEB_MAX_WIDTH } from '../../../constants/constants'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { Column } from '../../common/column/column'
import {
  LIGHT_MID_GRAY,
  PADDING_TO_TABS,
} from '../../../constants/ui-constants'

type DreamTabProps = {
  inGroup: boolean
  dream: FastDream | null | undefined
  showComments?: boolean
}

export const DreamTab = ({ inGroup, dream, showComments }: DreamTabProps) => {
  // HOOKS
  const { t } = useTranslation()

  // VARS
  const languageCode = (dream?.languageCode || 'en') as SupportedLanguage
  const description = dream?.description || ''
  const note = dream?.note || ''
  const scrollViewRef = React.useRef<KeyboardAwareScrollView>(null)

  const scrollToFocusedInput = (reactNode: any) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToFocusedInput(reactNode)
    }
  }

  return (
    <Tabs.ScrollView keyboardShouldPersistTaps={'always'}>
      {!dream ? (
        <LoadingPage />
      ) : (
        <FormScrollWrapper
          scrollViewRef={scrollViewRef}
          name={'add-dream-comment'}
          basic={true}
          contentContainerStyle={{
            maxWidth: WEB_MAX_WIDTH,
            width: '100%',
            marginHorizontal: 'auto',
          }}
        >
          <Column
            py={PADDING_TO_TABS}
            style={{ width: '100%', height: '100%', alignItems: 'flex-start' }}
          >
            <PaddedContentAreaConditional>
              <BodyText language={languageCode}>{description}</BodyText>
            </PaddedContentAreaConditional>
            {note && (
              <>
                <ContentDivider bgColor={LIGHT_MID_GRAY} my={8} />
                <PaddedContentAreaConditional>
                  <HeadingSans>{t('common.note')}</HeadingSans>
                  <BodyText language={languageCode}>{note}</BodyText>
                </PaddedContentAreaConditional>
              </>
            )}
            {dream.shareId && showComments && (
              <>
                <ContentDivider />
                <PaddedContentAreaConditional mobilePadding={2}>
                  <DreamComments
                    inGroup={inGroup}
                    dream={dream}
                    scrollToFocusedInput={scrollToFocusedInput}
                  />
                </PaddedContentAreaConditional>
              </>
            )}
          </Column>
        </FormScrollWrapper>
      )}
    </Tabs.ScrollView>
  )
}
