import { ExportLink } from './user/user'
import { FastDream, FastGroup, FastUser } from '../../../api/_openapi'

export function shallowEqualsUser(
  objA: FastUser | undefined,
  objB: FastUser | undefined,
): boolean {
  if (objA === undefined && objB === undefined) {
    return true
  }
  if (objA === undefined || objB === undefined) {
    return false
  }
  const idsEqual = objA.id === objB.id
  const usernamesEqual = objA.username === objB.username
  const emailsEqual = objA.email === objB.email
  const updatedAtEqual = objA.updatedAt === objB.updatedAt
  const imageEqual = objA.profileImageId === objB.profileImageId
  return (
    idsEqual && usernamesEqual && emailsEqual && updatedAtEqual && imageEqual
  )
}

export function shallowEqualsDream(
  objA: FastDream | null | undefined,
  objB: FastDream | null | undefined,
): boolean {
  if (!objA && !objB) {
    return true
  } else if (!objA || !objB) {
    return false
  } else {
    const idsEqual = objA.id === objB.id
    const updatedAtEqual = objA.updatedAt === objB.updatedAt
    const imagesEqual = objA.imageId === objB.imageId
    const shareIdEqual = objA.shareId === objB.shareId
    return idsEqual && updatedAtEqual && imagesEqual && shareIdEqual
  }
}

// "2023-07-28T21:40:22+00:00",
export function shallowEqualsDreamArray(
  arrA: FastDream[] | null | undefined,
  arrB: FastDream[] | null | undefined,
): boolean {
  if (!arrA && !arrB) {
    return true
  } else if (!arrA || !arrB) {
    return false
  } else if (arrA.length !== arrB.length) {
    return false
  } else {
    return arrA.every((dreamA, index) => {
      const dreamB = arrB[index]
      return shallowEqualsDream(dreamA, dreamB)
    })
  }
}

export function shallowEqualsExportLink(
  objA: ExportLink | undefined,
  objB: ExportLink | undefined,
): boolean {
  if (objA === undefined && objB === undefined) {
    return true
  } else if (objA === undefined || objB === undefined) {
    return false
  } else {
    return objA.code === objB.code && objA.expiry === objB.expiry
  }
}

export function shallowEqualsGroup(
  objA: FastGroup | null | undefined,
  objB: FastGroup | null | undefined,
) {
  if (objA === undefined && objB === undefined) {
    return true
  } else if (objA === undefined || objB === undefined) {
    return false
  } else {
    if (!objA && !objB) {
      return true
    } else if (!objA || !objB) {
      return false
    } else {
      const idsEqual = objA.id === objB.id
      const updatedAtEqual = objA.updatedAt === objB.updatedAt
      const descriptionEqual = objA.description === objB.description
      const nameEqual = objA.name === objB.name
      const imageEqual = objA.imageId === objB.imageId
      return (
        idsEqual &&
        updatedAtEqual &&
        descriptionEqual &&
        nameEqual &&
        imageEqual
      )
    }
  }
}

export function shallowEqualsGroupArray(
  arrA: FastGroup[] | null | undefined,
  arrB: FastGroup[] | null | undefined,
) {
  if (!arrA && !arrB) {
    return true
  } else if (!arrA || !arrB) {
    return false
  } else if (arrA.length !== arrB.length) {
    return false
  } else {
    return arrA.every((groupA, index) => {
      const groupB = arrB[index]
      return shallowEqualsGroup(groupA, groupB)
    })
  }
}

// we can use this to check if an object has changed very inexpensively.
export function shallowEqualIdsAndUpdatedDate<
  T extends { id: string; updatedAt?: string | null | undefined },
>(objA: T, objB: T): boolean {
  if (objA === objB) {
    return true
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false
  }

  if (objA.id !== objB.id || objA.updatedAt !== objB.updatedAt) {
    return false
  }

  return true
}
