import React, { FC, ReactNode } from 'react'
import i18n from '../../../i18n/i18nnext'
import { SupportedLanguage } from '../../../../../api/frontend-types'
import { TextProps, Text } from './text'
import { useColorModeValue } from 'native-base'
import {
  getBodyFont,
  getScriptTypeForLanguage,
} from '../../../modules/language-helpers/language-helpers'
import {
  BODY_FONT_WEIGHT_STR,
  LARGE_FONT_SIZE,
  LARGE_LINE_HEIGHT,
  LG_LINE_HEIGHT_ARABIC,
  SELECTION_COLOR,
} from '../../../constants/constants'
import {
  MUTED_TEXT_COLOR_DARK,
  MUTED_TEXT_COLOR_LIGHT,
} from '../../../constants/ui-constants'

type TextContentProps = TextProps & {
  children?: ReactNode
  type?: 'primary' | 'gray'
  language?: SupportedLanguage
}

export const BodyText: FC<TextContentProps> = ({
  children,
  fontSize = LARGE_FONT_SIZE,
  fontWeight = BODY_FONT_WEIGHT_STR,
  fontStyle = 'normal',
  type = 'primary',
  language,
  ...rest
}: TextContentProps) => {
  // VARS
  const lang: string = language || i18n.resolvedLanguage || 'en'
  const scriptType = getScriptTypeForLanguage(lang)
  const fontFamily = getBodyFont(lang)
  const dir = i18n.dir()
  const isRtl = dir === 'rtl'
  const isCyrillic = scriptType === 'cyrillic'
  const isArabic = scriptType === 'arabic'
  const textAlign = isRtl ? 'right' : 'left'
  const lh = isArabic ? LG_LINE_HEIGHT_ARABIC : LARGE_LINE_HEIGHT

  // No italics used in RTL languages (at least Arabic/Hebrew)
  // We don't have a cyrillic italic installed
  // TODO: perhaps render as bold instead for RTL?
  const fStyle = isRtl || isCyrillic ? 'normal' : fontStyle

  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )

  return (
    <Text
      // to display the correct italic font on mobile, need to specify 'body'
      fontFamily={fStyle === 'italic' ? 'body' : fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      selectionColor={SELECTION_COLOR}
      selectable={true}
      lineHeight={lh}
      color={type === 'gray' ? mutedColor : undefined}
      textAlign={textAlign}
      fontStyle={fStyle}
      {...rest}
    >
      {children}
    </Text>
  )
}
